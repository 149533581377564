export interface SponsorsCarouselProps {
  mainCarousel: boolean;
  shuffle: boolean;
  type : SponsorsType
}

export enum SponsorsType {
  BRONZE = 1,
  SILVER,
  GOLD,
}