import axios from "axios";
import EventState from "../../Store/actions/event/event.types";
import { URL_API, config } from "../config";
import { StrapTypeState } from "../../Store/actions/strap/strap.types";
import { DepositStateCommand } from "../Deposit/DepositHelper.types";
import { ExpenseState } from "../../Store/actions/expenses/expenses.types";

const strapExistById = (id: string): Promise<any> => {
  config.url = `${URL_API}/straps/${id}`;

  return axios(config)
    .then((response) => {
      return true;
    })
    .catch((response) => {
      return false;
    });
};

const strapExistByEncryptedId = (id: string): Promise<any> => {
  config.url = `${URL_API}/straps/encrypted/${id}`;
  const customHeader = config
  customHeader.headers['X-Strap-Encrypted-Identifier'] = id

  return axios(customHeader)
    .then((response) => {
      return true;
    })
    .catch((response) => {
      return false;
    });
};

export const strapExist = (id: string): Promise<any> => {
  if (id.length < 10) return strapExistById(id);
  else return strapExistByEncryptedId(id);
};

const getStrapByBasicId = (id: string): Promise<StrapInterface> => {
  config.url = `${URL_API}/straps/${id}`;

  return axios(config).then((response) => {
    return response.data.data;
  });
};

const getStrapByEncryptedId = (id: string): Promise<StrapInterface> => {
  config.url = `${URL_API}/straps/encrypted/${id}`;
  const customHeader = config
  customHeader.headers['X-Strap-Encrypted-Identifier'] = id

  return axios(customHeader).then((response) => {
    return response.data.data;
  });
};

export const getStrapById = (id: string): Promise<StrapInterface> => {
  if (id.length < 10) return getStrapByBasicId(id);
  else return getStrapByEncryptedId(id);
};

export const getPersonalDataExist = (id: string): Promise<boolean> => {
  config.url = `${URL_API}/straps/${id}/personal-banking-data`;

  return axios(config).then((response) => {
    if (response.data.data == null) {
      return false;
    } else {
      return true;
    }
  });
};

export const updateStrapById = (
  id: string,
  strapInfos: UpdateStrapInterface
): Promise<StrapInterface> => {
  const url = `${URL_API}/straps/${id}`;

  return axios.patch(
    url,
    { ...strapInfos },
    {
      headers: config.headers,
    }
  );
};

export const updatePersonalData = (
  id: string,
  personalData: PersonalDataInterface
): Promise<any> => {
  const url = `${URL_API}/straps/${id}/personal-banking-data`;
  const customHeader = config.headers
  customHeader["X-Strap-Encrypted-Identifier"] = id
  return axios.post(
    url,
    { ...personalData },
    {
      headers: customHeader,
    }
  );
};

export const checkStrapPinCode = (idStrap: string,pinCode:string): Promise<any> => {
  const customHeader = config
  customHeader.url = `${URL_API}/straps/${idStrap}/check-pin-code`
  customHeader.headers['X-Strap-Encrypted-Identifier'] = idStrap

  return axios.post(
    customHeader.url,
    { pincode: pinCode},
    {
      headers: customHeader.headers,
    }
  );
};

export interface StrapInterface {
  amount: number;
  email: string;
  event: EventState;
  id: number;
  idNfcTag: string;
  phoneNumber: string;
  orders:[]
  product: [];
  deposits: DepositStateCommand[];
  expenses: ExpenseState[];
  type: StrapTypeState;
  pinCodeCheckCounter : number
}

export interface UpdateStrapInterface {
  email?: string;
  phoneNumber?: string;
}

export interface PersonalDataInterface {
  firstName: string;
  lastName: string;
  address: string;
  zip: number;
  city: string;
  country: string;
  iban: string;
  email?: string
}
