import { OrderRowState } from "../../../Store/actions/orders/orders.types";

export interface TransactionItemProps {
  amount: number;
  products?: OrderRowState[];
  title?: string;
  createsTimestamp?: Date;
  transactionType?: number;
  idTransaction?: string;
  refNo: string;
  idDepositState?:number|null;
  refundable?:boolean
  expenses?: ExpensesInterface[]
}
export enum TransactionType {
  Pending,
  DepositVisa,
  DepositMastercard,
  DepositCash,
  DepositTwint,
  Purchase = 1000,

}

export interface ProductsInterface {
  quantity: number;
  price: number;
  title: string;
}

export interface ExpensesInterface {
  id: number;
  idExpenseType : number
  strapVoucher : {
    id : number
    idStrap : number
    idVoucher : number
    voucher : {
      id :number,
      idVoucherType : number,
      title : string
    }
    description: string,
    isConsumed: boolean
  }
  amount : number
  updatedDateTime:Date
}
