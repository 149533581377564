import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Footer, { getLinkForWhatsapp } from "../Footer/Footer";
import {
  displayContainerInTwoColums,
  styleAmountStrap,
  styleAmountStrapLabel,
  styleDetailContainer,
  styleDetailElement,
  styleStrapDetail,
  styleStrapDetailSeeMore,
} from "./RefundPage.styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import axios from "axios";
import {
  Alert,
  AlertTitle,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import {
  PersonalDataInterface,
  checkStrapPinCode,
  getPersonalDataExist,
  getStrapById,
  updatePersonalData,
} from "../../Api/Strap/StrapHelper";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../Store";
import { updateEvent } from "../../Store/actions/event/event";
import { updateStrap } from "../../Store/actions/strap/strap";
import { useSelector } from "react-redux";
import { CompleteState } from "../../Store/CompleteReducer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportIcon from "@mui/icons-material/Report";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/fr";
import { convertToGMTPlus1, isRefundActive } from "../../Utils.logik";
import PinField from "react-pin-field";
import "./styles.css";
import { CiCircleCheck } from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import EventState from "../../Store/actions/event/event.types";
import { useTranslation } from "react-i18next";

const enum FORM_STEPS {
  NOT_STARTED = -1,
  STEP_EMAILADRESS = 1,
  STEP_FULLFORM = 2,
  COMPLETED = 3,
  ERR_BALANCE0 = 4,
  ERR_NODEPOSITCASH,
  ERR_REFUNDEXPIRED,
}

export const MAX_PIN_TRY = 5;

function RefundPage() {
  const { t } = useTranslation();

  const { tokenid } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [formSteps, setFormSteps] = useState(-1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [npa, setNpa] = useState("");
  const [locality, setLocality] = useState("");
  const [iban, setIban] = useState("");
  const [country, setCountry] = useState("");
  const [conditions, setConditions] = useState(false);
  const [seeMore, setSeeMore] = useState<boolean>(false);
  // eslint-disable-next-line
  const [bankDetail, setBankDetail] = useState<string>(" ");
  const [email, setEmail] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [pinCodeCompleted, setPinCodeCompleted] = useState(false);
  const pinCoderef = useRef<HTMLInputElement[]>([]);
  const [pinCodeValid, setPinCodeValid] = useState(false);
  
  const [pinRemaining, setPinRemaining] = useState(MAX_PIN_TRY);
  const [dialogPinOpen, setDialogPinOpen] = useState(false);
  const [isLoadingIbanValidation, setIsLoadingIbanValidation] =
    useState<boolean>(false);

  // eslint-disable-next-line
  const [isFormValid, setIsFormValid] = useState(true);
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    address: false,
    npa: false,
    locality: false,
    iban: true,
    country: false,
    conditions: false,
    email: false,
  });

  const strap = useSelector((state: CompleteState) => state.strap);
  const event = useSelector((state: CompleteState) => state.event);

  useEffect(() => {
    if (tokenid) {
      getStrapById(tokenid)
        .then((response) => {
          // Dispatch Event informations
          dispatch(updateEvent(response.event));
          //Vérifier si une demande de remboursement a déjà été faite
          isPersonalDataExisting(response.id.toString(), response.event);

          setPinRemaining(MAX_PIN_TRY - response.pinCodeCheckCounter);
          // Dispatch Strap informations
          console.log(response)
          dispatch(
            updateStrap({
              id: response.id.toString(),
              idNfcTag: response.idNfcTag,
              idEncrypted: tokenid,
              type: {
                id: response.type.id,
                title: response.type.title,
                description: response.type.description,
              },
              email: response.email,
              phoneNumber: response.phoneNumber,
              amount: response.amount,
              pinCodeCheckCounter : response.pinCodeCheckCounter
            })
          );
        })
        .catch(() => navigate(`/`));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pinCode.length === 4) {
      if (tokenid) {
        checkStrapPinCode(tokenid, pinCode)
          .then((r) => {
            setPinCodeValid(true);
            setFormSteps(FORM_STEPS.STEP_FULLFORM);
          })
          .catch(() => {
            pinCoderef.current.forEach((input) => (input.value = ""));
            pinCoderef.current[0].focus();
            setPinRemaining(pinRemaining - 1);
          });
      }
    }
    // eslint-disable-next-line
  }, [pinCode]);

  const isPersonalDataExisting = (strapId: string, event: EventState) => {
    getPersonalDataExist(strapId).then((response) => {
      if (response) {
        setFormSteps(FORM_STEPS.COMPLETED);
      } else {
        setFormSteps(FORM_STEPS.STEP_EMAILADRESS);
      }

      //Vérifier si actuellement il y a une erreur
      checkIfErrors(event);
    });
  };

  const validateIban = (inputValue: string) => {
    setIban(inputValue);
    console.log("entrée", inputValue);
    //Supprime les espaces dans la chaîne de caractère
    const myInputWithoutSpaces = inputValue.replaceAll(" ", "").trim();

    console.log("sortie", myInputWithoutSpaces);

    if (myInputWithoutSpaces.length >= 15) {
      const config = {
        url: `https://openiban.com/validate/${myInputWithoutSpaces}`,
        method: "get",
      };
      setIsLoadingIbanValidation(true);
      return axios(config).then((response) => {
        if (response.data.valid === false) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            iban: true,
          }));

          setIsLoadingIbanValidation(false);
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            iban: false,
          }));
          setIsLoadingIbanValidation(false);
        }
      });
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        iban: true,
      }));
      setBankDetail(" ");
    }
  };

  const validateNpa = (value: string) => {
    const isValidNumber = /^\d+$/.test(value); // Vérifier si c'est un nombre
    //const isWithinMaxLength = value.length <= 6; // Vérifier la longueur maximale

    return isValidNumber && ~~value <= 99999;
  };

  const checkIfErrors = (event: EventState) => {
    if (strap.email === null) {
      setFormSteps(FORM_STEPS.ERR_NODEPOSITCASH);
    }

    if (strap.amount === 0) {
      setFormSteps(FORM_STEPS.ERR_BALANCE0);
    }

    console.log(event.endDateTime);
    if (isRefundActive(event.endDateTime) === false) {
      setFormSteps(FORM_STEPS.ERR_REFUNDEXPIRED);
    }
  };
  // eslint-disable-next-line
  const isValidEmail = (email: string) => {
    // Ajoutez votre logique de validation d'email ici
    // Par exemple, vous pouvez utiliser une expression régulière
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleValidation = () => {
    const formErrors = {
      firstName: !firstName,
      lastName: !lastName,
      address: !address,
      npa: !validateNpa(npa), // Valider le champ "NPA" avec la fonction personnalisée
      locality: !locality,
      iban: errors.iban,
      country: !country,
      conditions: !conditions,
      email: email.trim() === "" ? false : !isValidEmail(email),
    };

    const hasErrors = Object.values(formErrors).some((field) => field);

    if (hasErrors) {
      setIsFormValid(false);
      setErrors(formErrors);
    } else {
      setIsFormValid(true);
      setErrors({
        firstName: false,
        lastName: false,
        address: false,
        npa: false,
        locality: false,
        iban: false,
        country: false,
        conditions: false,
        email: false,
      });

      const datatoUpdate: PersonalDataInterface = {
        firstName: firstName,
        lastName: lastName,
        address: address,
        zip: Number(npa),
        city: locality,
        country: country,
        iban: iban.toUpperCase().replaceAll(" ", "").trim(),
        email: email,
      };

      updatePersonalData(strap.id ? strap.id : "", datatoUpdate)
        .then((response) => {
          setFormSteps(formSteps + 1);
        })
        .catch((e) => toast.error(t("Error.Message.PleaseTryLater")));
    }
  };

  /**
   * Rendu de la première partie du formulaire : champ e-mail
   */
  const renderStep1 = (
    <>
      <h3>
        {t("RefundForm.Pin.EnterPinCode")}
        {pinCodeCompleted}
      </h3>
      {pinRemaining !== 0 ? (
        <>
          <Box textAlign={"center"} fontSize={"0.8rem"} marginBottom={"7px"}>
            <div>
              {t("RefundForm.Pin.RemainingAttempt", { number: pinRemaining })}
            </div>
          </Box>
          <Box display={"flex"} gap={"13px"} justifyContent={"center"}>
            <PinField
              ref={pinCoderef}
              inputMode="numeric"
              type="number"
              className="field-a"
              validate={/^[0-9]$/}
              onChange={setPinCode}
              length={4}
              onComplete={() => setPinCodeCompleted(true)}
              format={(k) => k.toUpperCase()}
              disabled={pinCodeValid || pinRemaining === 0}
            />
          </Box>
          <Box
            textAlign={"center"}
            fontSize={"0.8rem"}
            marginTop={"7px"}
            marginBottom={"30px"}
          >
            <div
              style={{ color: "#8958FE", fontWeight: "bold" }}
              onClick={() => setDialogPinOpen(true)}
            >
              {t("RefundForm.Pin.WhereFind")}{" "}
            </div>
          </Box>
        </>
      ) : (
        <Alert variant="filled" severity="error">
          <AlertTitle>
            <b>{t("RefundForm.StrapBlocked.Title")}</b>
          </AlertTitle>
          {t("RefundForm.StrapBlocked.Sentence", { number: MAX_PIN_TRY })}
          <a
            style={{
              fontWeight: "bold",
              textDecoration: "none",
              color: "inherit",
            }}
            href={`mailto:refund@strapnpay.ch`}
          >
            refund@strapnpay.ch
          </a>{" "}
          {t("Common.Or")}{" "}
          <a
            style={{
              fontWeight: "bold",
              textDecoration: "none",
              color: "inherit",
            }}
            href={getLinkForWhatsapp(
              strap.idEncrypted ?? "",
              event.title ?? "Error"
            )}
          >
            <FaWhatsapp height={"16px"} style={{ marginRight: "5px" }} />
            WhatsApp
          </a>
          .
        </Alert>
      )}

      {/* <h3>Validation de l'adresse e-mail</h3>
      <TextField
        label="Adresse e-mail"
        helperText="Veuillez saisir l'adresse e-mail liée au bracelet (communiquée lors du dépôt au stand cash)"
        variant="filled"
        size="small"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={errors.email}
        sx={{ width: "100%" }}
        disabled={formSteps >= 2}
      /> */}
      {/* <div style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={validateEmail}
          sx={{ width: "auto" }}
          disabled={formSteps >= 2}
        >
          Suivant
        </Button>
      </div> */}
    </>
  );

  /**
   * Rendu de la seconde partie du formulaire : informations personnelles + iban
   */
  const renderStep2 = (
    <>
      <h3>Données personnelles</h3>
      {pinCodeValid && (
        <Box
          color={"green"}
          display={"flex"}
          alignItems={"center"}
          gap={"5px"}
          marginBottom={"17px"}
        >
          <CiCircleCheck size={20} color="green" /> Code pin valide
        </Box>
      )}
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box sx={{ ...displayContainerInTwoColums }}>
          <TextField
            label="Prénom *"
            variant="filled"
            size="small"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={errors.firstName}
            sx={{ width: "50%" }}
          />
          <TextField
            label="Nom *"
            variant="filled"
            size="small"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            error={errors.lastName}
            sx={{ width: "50%" }}
          />
        </Box>
        <TextField
          label="Adresse e-mail"
          //helperText="Veuillez saisir l'adresse e-mail liée au bracelet (communiquée lors du dépôt au stand cash)"
          variant="filled"
          size="small"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={errors.email}
          sx={{ width: "100%" }}
          //disabled={formSteps >= 2}
        />
        <TextField
          label="Adresse *"
          variant="filled"
          size="small"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          error={errors.address}
        />
        <Box sx={{ ...displayContainerInTwoColums }}>
          <TextField
            label="NPA *"
            variant="filled"
            size="small"
            value={npa}
            onChange={(e) => setNpa(e.target.value)}
            error={errors.npa}
            sx={{ width: "25%" }}
          />
          <TextField
            label="Localité *"
            variant="filled"
            size="small"
            value={locality}
            onChange={(e) => setLocality(e.target.value)}
            error={errors.locality}
            sx={{ width: "75%" }}
          />
        </Box>
        <FormControl variant="filled" size="small">
          <InputLabel id="country-label">Pays *</InputLabel>
          <Select
            labelId="country-label"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            error={errors.country}
          >
            <MenuItem value="Suisse">Suisse</MenuItem>
            <MenuItem value="France">France</MenuItem>
            <MenuItem value="Italie">Italie</MenuItem>
            <MenuItem value="Belgique">Belgique</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <h3>Coordonnées bancaires</h3>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <TextField
          label="IBAN *"
          variant="filled"
          size="small"
          value={iban}
          onChange={(e) => validateIban(e.target.value)}
          error={errors.iban}
          style={{ width: "100%" }}
          helperText={"Ex: CH78 0830 XXXX XXXX XXXX 5"}
        />
        <div style={{ marginTop: "-17px" }}>
          {isLoadingIbanValidation && <CircularProgress size={16} />}
          {!isLoadingIbanValidation && !errors.iban && (
            <CheckCircleOutlineIcon color="success" />
          )}
          {!isLoadingIbanValidation && errors.iban && (
            <ReportGmailerrorredIcon color="error" />
          )}
        </div>
      </Box>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <FormControlLabel
          sx={{ fontSize: "0.7rem" }}
          control={
            <Checkbox
              value={conditions}
              onChange={() => setConditions(!conditions)}
              sx={{
                color: errors.conditions ? red[800] : "inherit", // Appliquez la couleur rose si errors.conditions est true, sinon utilisez la couleur par défaut
              }}
            />
          }
          label={
            <Typography style={{ fontSize: "0.8rem" }}>
              Je confirme qu'il s'agit de mon bracelet et que les données
              renseignées sont correctes.
            </Typography>
          }
        />
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleValidation}
          disabled={errors.iban || isLoadingIbanValidation}
          sx={{ width: "100%" }}
        >
          Soumettre la demande
        </Button>
      </Box>
    </>
  );

  /**
   * Rendu du message de succès
   */
  const renderStep3 = (
    <div style={{ textAlign: "center" }}>
      <h3>La demande de remboursement a été envoyée</h3>
      <CheckCircleIcon
        sx={{
          color: "green",
          height: "100px",
          width: "100px",
          marginBottom: "1em",
        }}
      />
      <div>
        L'organisateur de l'événement va procéder au remboursement
        prochainement.
      </div>
    </div>
  );

  const renderStepError = (title: string, contentText: string) => (
    <div style={{ textAlign: "center" }}>
      <h3>{title}</h3>
      <ReportIcon
        sx={{
          color: "red",
          height: "100px",
          width: "100px",
          marginBottom: "1em",
        }}
      />
      <div>{contentText}</div>
    </div>
  );

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        limit={2}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Container maxWidth="sm">
        <img
          width={"150px"}
          src={process.env.PUBLIC_URL + `/sponsors/strapnpay.png`}
          alt="logo Strap'nPay."
        />
        <h1 style={{ marginTop: "0" }}>{t("RefundForm.Title")}</h1>
        {formSteps !== 3 ? <div>{t("RefundForm.Description")}</div> : null}
        <div
          style={{
            ...styleStrapDetail,
            borderRadius: "8px",
            textAlign: "center",
            marginTop: "1em",
          }}
        >
          <>
            <div style={styleAmountStrapLabel}>
              {t("RefundForm.AvailableBalance")}
            </div>
            <div style={styleAmountStrap}>
              {strap.amount
                ? (strap.amount / 100).toFixed(2) + " CHF"
                : "0 CHF"}
            </div>

            {seeMore ? (
              <div style={{ ...styleDetailContainer, fontSize: "0.8rem" }}>
                <div style={styleDetailElement}>
                  {/* TODO: display date */}
                  <b>{event.title}</b> du{" "}
                  {event.beginDateTime ? (
                    <Moment format="D MMMM YYYY hh:mm">
                      {convertToGMTPlus1(new Date(event.beginDateTime))}
                    </Moment>
                  ) : (
                    ""
                  )}{" "}
                  au{" "}
                  {event.endDateTime ? (
                    <Moment format="D MMMM YYYY hh:mm">
                      {convertToGMTPlus1(new Date(event.endDateTime))}
                    </Moment>
                  ) : (
                    ""
                  )}
                </div>

                <div style={styleDetailElement}>
                  <b>{t("Common.Term.StrapIdentifier")} </b>:{" "}
                  <i>{strap.idEncrypted}</i>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {/* eslint-disable-next-line */}
            <a
              onClick={() => setSeeMore(!seeMore)}
              href="#"
              style={styleStrapDetailSeeMore}
            >
              {`${seeMore ? t("Common.Less") : t("Common.More")} ${t(
                "Common.Term.Details"
              )}`}
            </a>
          </>
        </div>
        {event.withDrawalFee && (
          <Alert
            variant="outlined"
            severity="info"
            style={{ marginBottom: "10px", marginTop: "10px" }}
          >
            {t("Refund.OrganisationFees.Text", {
              fee: event.withDrawalFee / 100,
            })}
          </Alert>
        )}
        {formSteps === -1 ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress
              sx={{ width: "60px", height: "60px", marginTop: "1em" }}
            />
          </div>
        ) : null}
        {/* ... (autres éléments) */}
        {formSteps === FORM_STEPS.STEP_EMAILADRESS ? renderStep1 : null}
        {formSteps === FORM_STEPS.STEP_FULLFORM ? (
          <>
            {/* {renderStep1} */}
            {renderStep2}
          </>
        ) : null}
        {/* ... (autres éléments) */}
        {formSteps === FORM_STEPS.COMPLETED ? renderStep3 : null}
        {formSteps === FORM_STEPS.ERR_BALANCE0
          ? renderStepError(
              t("RefundForm.ErrorNotPossible.Title"),
              t("RefundForm.Error.Balance0")
            )
          : null}
        {formSteps === FORM_STEPS.ERR_REFUNDEXPIRED
          ? renderStepError(
              t("RefundForm.ErrorNotPossible.Title"),
              t("RefundForm.Error.ExpireContactEmail", {
                contactInfo: event?.email
                  ? t("RefundForm.Error.ContactInfo", { email: event.email })
                  : "",
              })
            )
          : null}
        {formSteps === FORM_STEPS.ERR_NODEPOSITCASH
          ? renderStepError(
              t("RefundForm.ErrorNoCashDeposit.Title"),
              t("RefundForm.ErrorNoCashDeposit.Text")
            )
          : null}
        <Dialog
          open={dialogPinOpen}
          keepMounted
          onClose={() => setDialogPinOpen(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{t("RefundForm.WhereFindPin.Title")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {t("RefundForm.WhereFindPin.Text")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogPinOpen(false)}>
              {t("Common.Close")}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      <Footer
        idStrap={strap.id ? strap.id : ""}
        eventTitle={event.title ? event.title : ""}
      ></Footer>
    </>
  );
}

export default RefundPage;
