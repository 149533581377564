const enum ProductSections {
    Mineral = 1,
    Beer,
    Wine,
    Aperitif,
    Spirits,
    Shot = 6,
    Meal,
    Miscellaneous,
    Cocktail = 9
}

export default ProductSections;