import { CSSProperties } from "react";

export const styleStrapInformationsDialogTitle: CSSProperties = {
  textAlign: "center",
  fontFamily: "Montserrat, Roboto",
  padding: "8px 12px",
};

export const styleStrapInformationsDialogContentContainer: CSSProperties = {
  padding: "8px 12px",
};

export const styleStrapInformationsDialogSpecificationsContainer: CSSProperties =
  { display: "flex", flexDirection: "column", rowGap: "8px" };

export const styleStrapInformationsDialogSpecificationTitle: CSSProperties = {
  fontSize: "0.6rem",
};

export const styleStrapInformationsDialogSpecificationContent: CSSProperties = {
  fontSize: "0.8rem",
  fontWeight:'600'
};
