import React, { useEffect } from "react";
import TransactionItem from "./TransactionItem/TransactionItem";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { CompleteState } from "../../Store/CompleteReducer";
import {
  getPrintTextByIdExpenseType,
  getTitleByIdDepositType,
  mixDepositAndTransactions,
} from "./TransactionItem/Transactions.logik";
import MessageDeposit from "../MessageDeposit/MessageDeposit";
import { DepositStateCommand } from "../../Store/actions/deposits/deposits.types";
import { OrderStateCommand } from "../../Store/actions/orders/orders.types";
import { ExpensesStateCommand } from "../../Store/actions/expenses/expenses.types";
import { t } from "i18next";

function Transaction() {
  const deposits = useSelector(
    (state: CompleteState) => state.deposits.deposits
  );
  const orders = useSelector((state: CompleteState) => state.orders.orders);
  const expenses = useSelector(
    (state: CompleteState) => state.expenses.expenses
  );

  const [allTransactions, setAllTransactions] = React.useState<
    (DepositStateCommand | OrderStateCommand | ExpensesStateCommand)[]
  >(mixDepositAndTransactions(deposits, orders, expenses));

  useEffect(() => {
    setAllTransactions(mixDepositAndTransactions(deposits, orders, expenses));
  }, [orders, deposits, expenses]);

  const [currentEndIndex, setcurrentEndIndex] = React.useState(5);
  const [displayedTransactions, setdisplayedTransactions] = React.useState(
    allTransactions.slice(0, currentEndIndex)
  );
  const [loading, setLoading] = React.useState(false);

  const seeMoreTransactionClick = () => {
    setLoading(true);
    setTimeout(() => {
      setcurrentEndIndex((prevEndIndex) => prevEndIndex + 5);
    }, 200); // 2000 millisecondes = 2 secondes
  };

  useEffect(() => {
    const transac = allTransactions.slice(0, currentEndIndex);
    setdisplayedTransactions(transac);
    setLoading(false);
  }, [currentEndIndex, allTransactions]);

  return (

      <div
        style={{
          background: "white",
          padding: "10px",
          borderRadius: "10px",
          boxShadow:
            "rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px",
          margin: "10px 5px",
        }}
      >
        <div
          style={{
            fontSize: "1rem",
            margin: "0px 0px 10px 0",
          }}
        >
          Transactions
        </div>
        <div
          style={{
            fontSize: "0.8rem",
          }}
        ></div>
        {allTransactions.length === 0 ? (
          <MessageDeposit />
        ) : (
          displayedTransactions.map((item, index) => (
            <>
              <TransactionItem
                key={index}
                amount={"amount" in item && item.amount ? item.amount : 0}
                products={
                  "orderRows" in item && item.orderRows ? item.orderRows : []
                }
                expenses={
                  "expenses" in item && item.expenses ? item.expenses : []
                }
                createsTimestamp={item.createdDateTime}
                transactionType={
                  "orderRows" in item && item.orderRows
                    ? 1000
                    : "idDepositType" in item && item.idDepositType
                    ? item.idDepositType
                    : "idExpenseType" in item && item.idExpenseType
                    ? item.idExpenseType
                    : 0 // Ou la valeur par défaut que vous souhaitez utiliser
                }
                title={
                  "salePoint" in item && item.salePoint
                    ? item.salePoint.title
                    : // : ("idDepositType" in item && item.idDepositType ?  getDepositTitle(item.idDepositType) : "Dépôt")
                    "idDepositType" in item && item.idDepositType
                    ? t("Common.Term.Deposit") +
                      " '" +
                      getTitleByIdDepositType(item.idDepositType) +
                      "'"
                    : "idExpenseType" in item && item.idExpenseType
                    ? getPrintTextByIdExpenseType(item.idExpenseType).summary
                    : "Consommation"
                }
                refNo={
                  "refNo" in item && item.refNo
                    ? item.refNo
                    : "idExpenseType" in item && item.idExpenseType
                    ? "expense"
                    : ""
                }
                idTransaction={
                  "idTransaction" in item && item.idTransaction
                    ? item.idTransaction
                    : undefined
                }
                idDepositState={
                  "idDepositState" in item ? item.idDepositState : null
                }
                refundable={
                  "refundable" in item && typeof item.refundable === "boolean"
                    ? item.refundable
                    : undefined
                }
              />
            </>
          ))
        )}

        {/* <div><CircularProgress size={'1rem'}/></div> */}
        <div style={{ textAlign: "center" }}>
          {currentEndIndex < allTransactions.length && (
            <Button
              style={{
                fontFamily: "Montserrat",
                fontSize: "0.7rem",
                color: "#9b9b9b",
                padding: "1px 10px",
                borderRadius: "10px",
              }}
              disabled={loading}
              onClick={seeMoreTransactionClick}
            >
              {loading && (
                <CircularProgress
                  size={"0.7rem"}
                  color="inherit"
                  style={{ marginRight: "5px" }}
                />
              )}
              {t("Common.SeeMore")}
            </Button>
          )}
        </div>
      </div>
    
  );
}

export default Transaction;
