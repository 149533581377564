/**
 * CompleteReducer - combine all single reducers to one
 * 
 */
import { combineReducers } from '@reduxjs/toolkit';
import eventReducer from './actions/event/event'
import strapReducer from './actions/strap/strap'
import ordersReducer from './actions/orders/orders'
import sponsorsReducer from './actions/sponsors/sponsors'
import uiReducer from './actions/ui/ui'
import depositsReducer from './actions/deposits/deposits'
import expensesReducer from './actions/expenses/expenses'
import vouchersReducer from './actions/vouchers/vouchers'

const completeReducer = combineReducers({
    strap: strapReducer,
    event: eventReducer,
    orders: ordersReducer,
    sponsors: sponsorsReducer,
    ui: uiReducer,
    deposits: depositsReducer,
    expenses : expensesReducer,
    vouchers : vouchersReducer
});

export type CompleteState = ReturnType<typeof completeReducer>;
export default completeReducer;
