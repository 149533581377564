import { Box } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import PinField from "react-pin-field";
import { useParams } from "react-router-dom";
import { checkStrapPinCode } from "../../Api/Strap/StrapHelper";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { CompleteState } from "../../Store/CompleteReducer";
import { MAX_PIN_TRY } from "../RefundPage/RefundPage";

interface PinCodeInputProps {
  onPinValid: () => void;
  setValidPinCode:(pin : string) => void;
}

function PinCodeInput(props: PinCodeInputProps) {
  const strap = useSelector((state: CompleteState) => state.strap);
  const { id } = useParams();
  const pinCoderef = useRef<HTMLInputElement[]>([]);
  const [pinCode, setPinCode] = useState("");
  // eslint-disable-next-line
  const [pinCodeCompleted, setPinCodeCompleted] = useState<boolean>(false);
  const [pinCodeValid, setPinCodeValid] = useState(false);
  const [pinTryCount, setPinTryCount] = useState(
    strap.pinCodeCheckCounter || 0
  );
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (pinCode.length === 4) {
      if (id) {
        setIsLoading(true)
        checkStrapPinCode(id, pinCode)
          .then((r) => {
            setPinCodeValid(true);
            props.setValidPinCode(pinCode)
            props.onPinValid();
          })
          .catch(() => {
            toast.error(t("PinInput.Error.Message"), {
              position: "top-center",
            });
            setPinTryCount((value) => {
              return value + 1;
            });
            pinCoderef.current.forEach((input) => (input.value = ""));
            pinCoderef.current[0].focus();
          }).finally(() => {
            setIsLoading(false)
          });
      }
    }
    // eslint-disable-next-line
  }, [pinCode]);

  return (
    <>
      <Box
        textAlign={"center"}
        fontSize={"0.8rem"}
        marginTop={"7px"}
        marginBottom={"10px"}
      >
        <div style={{ color: "#8958FE", fontWeight: "bold" }}>
          {t("RefundForm.Pin.WhereFind")}{" "}
        </div>
        <div>{t('RefundForm.WhereFindPin.Text')}</div>
      </Box>
      <Box display={"flex"} gap={"13px"} justifyContent={"center"}>
        <PinField
        
          ref={pinCoderef}
          inputMode="numeric"
          type="number"
          className="field-a"
          validate={/^[0-9]$/}
          onChange={setPinCode}
          length={4}
          onComplete={() => setPinCodeCompleted(true)}
          format={(k) => k.toUpperCase()}
          disabled={pinCodeValid || pinTryCount >= MAX_PIN_TRY || isLoading}
        />
      </Box>
      <Box fontSize={'0.8rem'} textAlign={'center'} marginTop={'10px'}>
        { pinTryCount>= MAX_PIN_TRY ? (
            <div style={{color:'red'}}>{t('PinInput.Error.StrapBlocked')}</div>
        ):(
            <div>{MAX_PIN_TRY - pinTryCount} {t('PinInput.Try.Remaining')}</div>
        )}
      </Box>
    </>
  );
}

export default PinCodeInput;
