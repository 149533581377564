import axios from "axios";
import { URL_API, config } from "../config";
import { SponsorStateCommand } from "../../Store/actions/sponsors/sponsors.types";

/**
 *
 * @param idEvent event id
 * @returns
 */
export const getSponsorsFromEventId = async (idEvent: number): Promise<SponsorStateCommand[]> => {
  config.url = `${URL_API}/events/${idEvent}/sponsors`;

  try {
    return axios(config).then((response) => {
      return response.data.data;
    });
  } catch (error) {
    throw error;
  }
};
