import React from "react";
import { VoucherItemProps } from "./VoucherItem.types";
import { Badge, BadgeProps, Box, styled } from "@mui/material";
import { IoBeerOutline, IoFastFoodOutline } from "react-icons/io5";
import ProductSections from "../../../ProductSections.types";
import { LiaCocktailSolid } from "react-icons/lia";
import { RiCoupon3Line } from "react-icons/ri";
import { LuGlassWater, LuWine } from "react-icons/lu";
import Moment from "react-moment";
import moment from "moment";
import { t } from "i18next";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    left: 12  ,
    top: 3,
    //border: `2px solid ${theme.palette.background.paper}`,
    //padding: "0 4px",
  },
}));

function VoucherItem(props: VoucherItemProps) {
  const iconStyle = { width: "40px", height: "40px", color: "#7b7b7b" };
  console.log(props);

  const renderSectionAndProduct = (): JSX.Element => {
    const section = props.voucher.productSection;
    const product = props.voucher.product;

    let components: JSX.Element[] = [];

    if (section) {
      components.push(
        <div key="section">
          {t("Voucher.Category.Title")} {section.title}
        </div>
      );
    }

    if (product) {
      components.push(
        <div key="product">
          {t("Voucher.Product.Title")} {product.title}
        </div>
      );
    }

    return <>{components}</>;
  };

  const renderIcon = (idProductSection: number | null): JSX.Element => {
    if (idProductSection !== null) {
      return renderIconForCategoryVoucher(idProductSection);
    } else {
      return <RiCoupon3Line style={iconStyle} />;
    }
  };

  const renderIconForCategoryVoucher = (
    productSection: number
  ): JSX.Element => {
    switch (productSection) {
      case ProductSections.Mineral:
        return <LuGlassWater style={iconStyle} />;
      case ProductSections.Beer:
      case ProductSections.Aperitif:
        return <IoBeerOutline style={iconStyle} />;
      case ProductSections.Wine:
      case ProductSections.Shot:
      case ProductSections.Spirits:
        return <LuWine style={iconStyle} />;
      case ProductSections.Meal:
        return <IoFastFoodOutline style={iconStyle} />;
      case ProductSections.Miscellaneous:
        return <RiCoupon3Line style={iconStyle} />;
      case ProductSections.Cocktail:
        return <LiaCocktailSolid style={iconStyle} />;
      default:
        return <RiCoupon3Line style={iconStyle} />;
    }
  };

  const renderPrice = (): JSX.Element => {
    const minPrice = props.voucher.minPrice;
    const maxPrice = props.voucher.maxPrice;
    if (minPrice !== null && maxPrice !== null) {
      return (
        <>
          {t("Common.Price.Title")}
          {" : "}
          {`de ${(minPrice / 100).toFixed(2)} à ${(maxPrice / 100).toFixed(
            2
          )} CHF`}
        </>
      );
    } else if (minPrice !== null) {
      return (
        <>
          {t("Common.Price.Title")} :{" "}
          {`${(minPrice / 100).toFixed(2)} CHF (minimum)`}
        </>
      );
    } else if (maxPrice !== null) {
      return (
        <>
          {t("Common.Price.Title")} :{" "}
          {`${(maxPrice / 100).toFixed(2)} CHF (maximum)`}
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Box
        display={"flex"}
        border={"2px solid #e6e9ed"}
        padding={"15px 10px"}
        alignItems={"center"}
        width={"85%"}
        gap={"12px"}
        fontSize={"0.8rem"}
        style={
          props.isConsumed
            ? {
                background:
                  "linear-gradient(45deg, #e6e9ed 12.5%, #fff 12.5%, #fff 37.5%, #e6e9ed 37.5%, #e6e9ed 62.5%, #fff 62.5%, #fff 87.5%, #e6e9ed 87.5%)",
                backgroundSize: "40px 40px",
                backgroundPosition: "50px 50px",
              }
            : {}
        }
      >
        <StyledBadge
          badgeContent={props.quantity && props.quantity > 0 ? props.quantity + "x":null}
          color="secondary"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div
            style={{
              minWidth: "50px",
              minHeight: "50px",
              alignContent: "center",
            }}
          >
            {renderIcon(
              props.voucher.productSection
                ? props.voucher.productSection?.id
                : null
            )}
          </div>
        </StyledBadge>
        <Box
          style={{
            textAlign: "left",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <div style={{ marginBottom: "5px" }}>
              <strong>{props.voucher.title}</strong>
            </div>
            {renderSectionAndProduct()}
            {renderPrice()}
            {props.isConsumed ? (
              <div style={{ fontStyle: "italic" }}>
                {`Utilisé : le `}
                <Moment format="D MMMM YYYY HH:mm" tz="Europe/Paris">
                  {moment.utc(props.updatedDateTime).local().format()}
                </Moment>
              </div>
            ) : null}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default VoucherItem;
