import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { getStrapById } from "../../Api/Strap/StrapHelper";
import { useAppDispatch } from "../../Store";
import { CompleteState } from "../../Store/CompleteReducer";
import { updateDeposits } from "../../Store/actions/deposits/deposits";
import { updateOrder } from "../../Store/actions/orders/orders";
import { updateStrap } from "../../Store/actions/strap/strap";
import DepositButton from "../DepositButton/DepositButton";
import UsefulLinks from "../UsefulLinks/UsefulLinks";
import { updateUiState } from "../../Store/actions/ui/ui";
import { updateExpenses } from "../../Store/actions/expenses/expenses";
import { convertToGMTPlus1 } from "../../Utils.logik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Voucher from "./Voucher/Voucher";
import { getVouchersFromStrap } from "../../Api/Voucher/VoucherHelper";




function Balance() {
  const { t } = useTranslation();
  const strap = useSelector((state: CompleteState) => state.strap);
  const event = useSelector((state: CompleteState) => state.event);

  const dispatch = useAppDispatch();

  const onRefreshClick = () => {
    if (strap === undefined || strap.id === undefined) return;
    getStrapById(strap.id).then((response) => {
      // Dispatch Strap informations
      dispatch(
        updateStrap({
          amount: response.amount,
        })
      );

      //Garder uniquement les déposits Settled (valides)
      const filteredDeposits = response.deposits.filter(
        (deposit) => deposit.idDepositState === 2
      );
      dispatch(updateDeposits(filteredDeposits));
      dispatch(updateExpenses(response.expenses));
      dispatch(updateOrder(response.orders));
    });

    getVouchersFromStrap(strap.id).then(() => {});
  };


  const currentTime = new Date(Date.now());

  const canOpenDepositModal =
    event.beginDateTime &&
    event.endDateTime &&
    currentTime > convertToGMTPlus1(new Date(event.beginDateTime)) &&
    currentTime < convertToGMTPlus1(new Date(event.endDateTime));

  const handleClickBalance = () => {
    if (canOpenDepositModal) {
      dispatch(updateUiState({ formDepositAmount: true }));
    } else {
      toast.error(t('Balance.Event.IsFinish'), { position: "top-center" });
    }
  };

  return (
    <div
      style={{
        position: "relative",
        padding: "10px",
        borderRadius: "10px",
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
        background:
          "linear-gradient(135deg, rgba(8,82,251,1) 10%, rgba(9,167,250,1))",
        color: "white",
        textAlign: "center",
        margin: "10px 0px ",
      }}
    >
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: "0px",
            width: "80px",
            height: "80px",
            zIndex: "0",
            backgroundImage: 'url("/utils/money.png")',
            backgroundPosition: "center center",
            backgroundSize: "cover",
            transform: "translateY(-50%)",
            top: "50%",
          }}
          hidden
        ></div>
        <div>{t("Balance.YourBalance.Title")}</div>
        <div className="balance-container">
          <div className="balance" onClick={() => handleClickBalance()}>
            <span
              style={{
                fontWeight: "300",
              }}
            >
              CHF
            </span>
            <span
              style={{
                fontSize: "2.5rem",
                fontWeight: "700",
              }}
            >
              {(strap !== undefined && strap.amount !== undefined
                ? strap.amount / 100
                : 0
              ).toFixed(2)}
            </span>
          </div>
        </div>
        <Box display={"flex"} flexDirection={'row'} justifyContent={'center'}alignItems={'center'}>
          <DepositButton />
          <Voucher></Voucher>
        </Box>
      </div>
      <UsefulLinks />
      <IconButton
      id="refresh-button"
        style={{
          position: "absolute",
          top: 6,
          right: 6,
        }}
        color="inherit"
        onClick={onRefreshClick}
      >
        <RefreshIcon />
      </IconButton>
    </div>
  );
}

export default Balance;
