import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StrapStateCommand } from "./strap.types";

export const initialState: StrapStateCommand = {
  id: undefined,
  idNfcTag: undefined,
  type: undefined,
  email: undefined,
  phoneNumber: undefined,
  amount: undefined,
};

const strap = createSlice({
  name: "strap",
  initialState,
  reducers: {
    updateStrap(
      state: StrapStateCommand,
      action: PayloadAction<StrapStateCommand>
    ) {
      if (action.payload.id !== undefined) {
        state.id = action.payload.id;
      }
      if (action.payload.idNfcTag !== undefined) {
        state.idNfcTag = action.payload.idNfcTag;
      }
      if (action.payload.type !== undefined) {
        state.type = action.payload.type;
      }
      if (action.payload.email !== undefined) {
        state.email = action.payload.email;
      }
      if (action.payload.phoneNumber !== undefined) {
        state.phoneNumber = action.payload.phoneNumber;
      }
      if (action.payload.amount !== undefined) {
        state.amount = action.payload.amount;
      }
      if(action.payload.idEncrypted !== undefined) {
        state.idEncrypted = action.payload.idEncrypted;
      }
      if(action.payload.pinCodeCheckCounter !== undefined) {
        state.pinCodeCheckCounter = action.payload.pinCodeCheckCounter;
      }
    },
    //method for reset state
    resetStrapState(state: StrapStateCommand) {
      state.id = initialState.id;
      state.idNfcTag = initialState.idNfcTag;
      state.type = initialState.type;
      state.email = initialState.email;
      state.phoneNumber = initialState.phoneNumber;
      state.amount = initialState.amount;
    },
  },
});

export const { updateStrap, resetStrapState } = strap.actions;
export default strap.reducer;
