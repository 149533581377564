import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import {
  UpdateStrapInterface,
  updateStrapById,
} from "../../Api/Strap/StrapHelper";
import { useSelector } from "react-redux";
import { CompleteState } from "../../Store/CompleteReducer";
import { toast } from "react-toastify";
import "./FormUpdatePersonalInfo.styles.css";
import { useAppDispatch } from "../../Store";
import { updateUiState } from "../../Store/actions/ui/ui";

function FormUpdatePersonalInfo() {
  const dispatch = useAppDispatch();
  const open = useSelector(
    (state: CompleteState) => state.ui.formPersonalInfoIsOpen
  );
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [valueEmail, setEmailValue] = useState<string>(
    useSelector((state: CompleteState) => state.strap.email as string) || ""
  );
  const [valuePhone, setPhoneValue] = useState<string>(
    useSelector((state: CompleteState) => state.strap.phoneNumber) || ""
  );
  const strapId = useSelector((state: CompleteState) => state.strap.id);

  function isValidEmail() {
    return /\S+@\S+\.\S+/.test(valueEmail);
  }

  function isValidPhoneNumberInput() {
    const isPossible = isPossiblePhoneNumber(valuePhone);
    const isValid = isValidPhoneNumber(valuePhone);
    return isPossible && isValid;
  }

  const handleChangePhoneNumber = (value: string) => {
    if (value === undefined) value = "";
    setPhoneValue(value);
  };

  const handleChangeCheckBox = () => {
    setCheckbox(!checkbox);
  };

  const validateForm = () => {
    setLoading(true);
    const isEmailValid = isValidEmail();
    const isPhoneNumberValid = isValidPhoneNumberInput();

    if ((isEmailValid || isPhoneNumberValid) && !checkbox) {
      let propertiesToUpdate: UpdateStrapInterface = {};
      if (isEmailValid) {
        propertiesToUpdate.email = valueEmail;
      }
      if (isPhoneNumberValid) {
        propertiesToUpdate.phoneNumber = valuePhone;
      }
      updateStrapById(strapId !== undefined ? strapId : "", propertiesToUpdate)
        .then((response) => {
          dispatch(updateUiState({ formPersonalInfoIsOpen: false }));
          toast.success("Données personnelles mises à jour.");
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (checkbox) {
      dispatch(updateUiState({ formPersonalInfoIsOpen: false }));
      //TODO : PATCH la propriété pour connaître si le user veut entrer ses données.
      toast.success("Préférences mises à jour.");
    } else {
      // Faire quelque chose si l'email ou le numéro de téléphone est invalide
      toast.error("L'email ou le numéro de téléphone est invalide !");
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>Données personnelles</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="info">
              Vos données ne sont pas diffusées. Elles nous permettent de vous
              contacter en cas de problème avec votre bracelet.
            </Alert>
          </DialogContentText>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={valueEmail}
              onChange={(event) => setEmailValue(event.target.value)}
              label="Adresse e-mail"
              type="email"
              fullWidth
              variant="standard"
              disabled={checkbox}
            />
            <PhoneInput
              defaultCountry="CH"
              countries={["FR", "CH", "IT", "ES"]}
              international
              placeholder="Numéro de téléphone"
              value={valuePhone}
              onChange={handleChangePhoneNumber}
              disabled={checkbox}
            />
          </div>
          <Divider style={{ margin: "10px 0" }}></Divider>
          <FormControlLabel
            control={
              <Checkbox
                value={checkbox}
                onChange={handleChangeCheckBox}
                name="gilad"
                size="small"
              />
            }
            label={
              <span style={{ fontSize: "0.8rem" }}>
                Je ne souhaite pas entrer mes données personnelles
              </span>
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              dispatch(updateUiState({ formPersonalInfoIsOpen: false }))
            }
          >
            Annuler
          </Button>
          <Button onClick={validateForm} disabled={loading}>
            {loading ? <CircularProgress size={15} /> : "Mettre à jour"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormUpdatePersonalInfo;
