import { CSSProperties } from "react";

export const styleStrapDetail: CSSProperties = {
  padding: "20px 10px",
  background: "linear-gradient(135deg, rgb(8, 82, 251) 10%, rgb(9, 167, 250))",
  color: "white",
  fontSize:'1rem'
};

export const styleAmountStrapLabel: CSSProperties = {
  fontSize: "0.8rem",
};

export const styleAmountStrap: CSSProperties = {
  fontSize: "1.8rem",
  fontWeight: "bolder",
  marginBottom:'8px'
};

export const styleStrapDetailSeeMore: CSSProperties = {
  color: "white",
  textDecoration: "none",
  //background: "white",
  padding: "3px 5px",
  borderRadius: "10px",
  fontSize: "0.7rem",
  textAlign: "center",
  marginTop:'100px',
  margin:'5px',
  border:'1px solid white'
};

export const styleDetailContainer : CSSProperties ={
    margin:'10px ',
    fontFamily:'0.8rem'
}

export const styleDetailElement : CSSProperties ={
    margin:'5px ',
}

export const displayContainerInTwoColums : CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "10px",
}