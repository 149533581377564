import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

// Importe tes fichiers de traduction
import enTranslation from "./locale/en.json";
import frTranslation from "./locale/fr.json";
import deTranslation from "./locale/de.json";
import itTranslation from "./locale/it.json";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['de', 'en', 'fr', 'it'],
    resources: {
      en: {
        translation: enTranslation,
      },
      fr: {
        translation: frTranslation,
      },
      de: {
        translation: deTranslation,
      },
      it: {
        translation: itTranslation,
      },
    },
    //lng: "fr", // Définit la langue initiale de l'application
    fallbackLng: "fr", // Langue de secours si la langue détectée n'est pas disponible
  });