import { useEffect, useState } from "react";
import "./App.css";
import Balance from "./Components/Balance/Balance";
import SponsorsCarousel from "./Components/SponsorsCarousel/SponsorsCarousel";
import Transaction from "./Components/Transaction/Transactions";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./Components/HomePage/HomePage";
import { getStrapById } from "./Api/Strap/StrapHelper";
import Loading from "./Components/Loading/Loading";
import { Provider, useSelector } from "react-redux";
import store from "./Store";
import { useAppDispatch } from "../src/Store";
import { addEventLink, updateEvent } from "../src/Store/actions/event/event";
import { updateStrap } from "./Store/actions/strap/strap";
import Header from "./Components/Header/Header";
import { updateOrder } from "./Store/actions/orders/orders";
import { getSponsorsFromEventId } from "./Api/Sponsors/SponsorsHelper";
import { updateSponsors } from "./Store/actions/sponsors/sponsors";
import FormUpdatePersonalInfo from "./Components/FormUpdatePersonalInfo/FormUpdatePersonalInfo";
import { ToastContainer } from "react-toastify";
import Footer from "./Components/Footer/Footer";
import FormDeposit from "./Components/FormDeposit/FormDeposit";
import { updateDeposits } from "./Store/actions/deposits/deposits";
import RefundPage from "./Components/RefundPage/RefundPage";
import { updateExpenses } from "./Store/actions/expenses/expenses";
import axios from "axios";
import { CompleteState } from "./Store/CompleteReducer";
import { SponsorsType } from "./Components/SponsorsCarousel/SponsorsCarousel.types";
import { useTranslation } from "react-i18next";
import "./i18n";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="" element={<HomePage />} />
          <Route path="/:id" element={<BraceletDetail />} />
          <Route path="/loading" element={<Loading />} />
          <Route path="/refund/:tokenid" element={<RefundPage />} />
        </Routes>
      </Router>
    </Provider>
  );
}

function BraceletDetail() {
  const { t } = useTranslation();
  const event = useSelector((state: CompleteState) => state.event);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [idEvent, setIdEvent] = useState<number | undefined>();

  axios.defaults.headers.common["X-Strap-Encrypted-Identifier"] = id;

  useEffect(() => {
    if (id) {
      getStrapById(id)
        .then((response) => {
          // Dispatch Event informations

          dispatch(updateEvent(response.event));
          setIdEvent(response.event.id);

          //Ajout d'un liens par défaut sur tous les éléments
          //  pour afficher les informations sur les remboursements.
          dispatch(
            addEventLink({
              id: -1,
              idEvent: -1,
              title: t("Links.Refund.Title"),
              icon: "HelpOutline",
              uri: "pop-up-remboursement",
            })
          );
          dispatch(
            addEventLink({
              id: 0,
              idEvent: -1,
              title: t("Links.InstructionsForUse.Title"), // Utilisez t à l'extérieur de l'objet
              icon: "HelpOutline",
              uri: "https://link.strapnpay.ch/tuto",
            })
          );
          //Ajouter si Festival promo
          if(response.event.id === 9){
            dispatch(
              addEventLink({
                id: -2,
                idEvent: -1,
                title: t("Links.BuyTickets.Title"),
                icon: "HelpOutline",
                uri: "pop-up-buytickets",
              })
            );
          }


          // Dispatch Strap informations
          dispatch(
            updateStrap({
              id: response.id.toString(),
              idNfcTag: response.idNfcTag,
              idEncrypted: id,
              type: {
                id: response.type.id,
                title: response.type.title,
                description: response.type.description,
              },
              email: response.email,
              phoneNumber: response.phoneNumber,
              amount: response.amount,
              pinCodeCheckCounter:response.pinCodeCheckCounter
            })
          );
          // Garder uniquement les dépôts avec les ID de dépôt 2, 7, 8, 9
          const validDepositStateIds = [2, 7, 8, 9];
          const filteredDeposits = response.deposits.filter((deposit) =>
            validDepositStateIds.includes(deposit.idDepositState)
          );

          dispatch(updateDeposits(filteredDeposits));
          dispatch(updateExpenses(response.expenses));

          dispatch(updateOrder(response.orders));

          if (response.event.id) {
            getSponsorsFromEventId(response.event.id).then((response) => {
              dispatch(updateSponsors(response));
            });
          }
          setLoading(false);
        })
        //Redirection si le strap n'existe pas
        .catch(() => navigate(`/`));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        limit={2}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          <FormUpdatePersonalInfo />
          <FormDeposit />
          <Header />
          <Balance />
          <SponsorsCarousel type={SponsorsType.GOLD} shuffle mainCarousel />
          <Transaction />
          <SponsorsCarousel
            type={SponsorsType.BRONZE}
            shuffle
            mainCarousel={false}
          />
          <Footer
            idStrap={id ? id : ""}
            eventTitle={event.title ? event.title : ""}
          />
        </>
      )}
    </>
  );
}

export default App;
