import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";

import { SponsorsCarouselProps } from "./SponsorsCarousel.types";
import { useSelector } from "react-redux";
import { CompleteState } from "../../Store/CompleteReducer";
import { SponsorStateCommand } from "../../Store/actions/sponsors/sponsors.types";

const defaultPartners: SponsorStateCommand[] = [
  {
    idSponsor: -1,
    sponsor: {
      id: -1,
      title: "strapnpay",
      imageUrl: "https://assets.strapnpay.ch/me/sponsor/strapnpay.png",
      link: "https://strapnpay.ch/",
    },
    idSponsorType: 1,
  },
  {
    idSponsor: -2,
    sponsor: {
      id: -2,
      title: "payrexx",
      imageUrl: "https://assets.strapnpay.ch/me/sponsor/payrexx.png",
      link: "https://payrexx.com/",
    },
    idSponsorType: 1,
  },
  {
    idSponsor: -3,
    sponsor: {
      id: -3,
      title: "zoppè",
      imageUrl: "https://assets.strapnpay.ch/me/sponsor/zoppe.png",
      link: "https://www.zoppesa.ch/",
    },
    idSponsorType: 1,
  },
];

function SponsorsCarousel(props: SponsorsCarouselProps) {
  const SLIDER_HEIGHT = props.mainCarousel ? "75px" : "35px";

  //Retrieve data
  const allSponsors = useSelector(
    (state: CompleteState) => state.sponsors.sponsors
  );
  const [randomSponsors, setRandomSponsors] = useState<SponsorStateCommand[]>(
    []
  );

  useEffect(() => {
    if (props.shuffle) {
      shuffleSponsors();
    }
    // eslint-disable-next-line
  }, [allSponsors]); // tableau vide pour n'exécuter l'effet qu'une seule fois au montage du composant

  /**
   * Fonction qui permet de mélanger l'ordre des allSponsors
   */
  const shuffleSponsors = () => {
    let shuffledSponsors = [...allSponsors]; // copie du tableau de allSponsors
    shuffledSponsors = shuffledSponsors.concat(defaultPartners);
    shuffledSponsors.sort(() => Math.random() - 0.5); // tri aléatoire

    let filteredSponsors = shuffledSponsors.filter((sponsor) => {
      return sponsor.idSponsorType === props.type;
    });

    if (filteredSponsors.length <= 3) {
      filteredSponsors = filteredSponsors.concat(filteredSponsors);
    }
    setRandomSponsors(filteredSponsors); // Utilisez filteredSponsors ici au lieu de shuffledSponsors
  };

  return (
    <div>
      <Swiper
        slidesPerView={props.mainCarousel ? 2.5 : 3.5}
        spaceBetween={props.mainCarousel ? 20 : 10}
        autoplay={{
          delay: 3500,
        }}
        //loop={true}
        modules={[Autoplay, Pagination]}
        className={
          props.mainCarousel ? "mainSwiperSponsor" : "secondarySwiperSponsor"
        }
        style={{
          justifyContent: "center",
          margin: "0 -5px 0 -5px",
        }}
      >
        {randomSponsors.map((sponsor, index) => (
          <SwiperSlide key={index}>
            <a href={sponsor.sponsor.link} target="_blank" rel="noreferrer">
              <img
                src={sponsor.sponsor.imageUrl}
                alt={sponsor.sponsor.title}
                style={{
                  width: "100%",
                  height: SLIDER_HEIGHT,
                  objectFit: "scale-down",
                }}
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default SponsorsCarousel;
