import { OrderStateCommand } from "../../../Store/actions/orders/orders.types";
import { DepositStateCommand } from "../../../Store/actions/deposits/deposits.types";
import { ExpensesStateCommand } from "../../../Store/actions/expenses/expenses.types";
import { t } from "i18next";

/**
 *
 * @param order
 * @returns
 */
export const calculateOrderSum = (order: OrderStateCommand): number => {
  let orderSum = 0;
  const orderRows = order.orderRows;
  orderRows?.forEach((orderRow) => {
    orderSum += orderRow.quantity * orderRow.salePointProduct.price;
  });
  return orderSum;
};

export const mixDepositAndTransactions = (
  deposits: DepositStateCommand[],
  orders: OrderStateCommand[],
  expenses: ExpensesStateCommand[]
): (DepositStateCommand | OrderStateCommand | ExpensesStateCommand)[] => {
  const mergedData: (
    | DepositStateCommand
    | OrderStateCommand
    | ExpensesStateCommand
  )[] = [...deposits, ...orders, ...expenses];

  const sortedData = mergedData.sort((a, b) => {
    const dateA = a.createdDateTime ? new Date(a.createdDateTime) : null;
    const dateB = b.createdDateTime ? new Date(b.createdDateTime) : null;

    if (dateA && dateB) {
      if (dateA > dateB) {
        return -1;
      }
      if (dateA < dateB) {
        return 1;
      }
      return 0;
    }
    // Gérez le cas où au moins l'une des propriétés createdDateTime est indéfinie
    return 0;
  });

  return sortedData;
};

export const getTitleByIdDepositType = (idDepositType: number): string => {
  switch (idDepositType) {
    case 1:
      return "Pending";
    case 2:
      return "Cash";
    case 3:
      return "Maestro";
    case 4:
      return "MasterCard";
    case 5:
      return "Twint";
    case 6:
      return "PostFinance Card";
    case 7:
      return "Post Finance eFinance";
    case 8:
      return "VISA";
    case 9:
      return "V Pay";
    case 10:
      return "Swisscom Pay";
    case 11:
      return "Google Pay";
    case 12:
      return "Samsung Pay";
    case 13:
      return "Apple Pay";
    default:
      return "Unknown";
  }
};

export interface printTextExpense {
  summary: string;
  detail: string;
}

export const getPrintTextByIdExpenseType = (
  idExpenseType: number
): printTextExpense => {
  let printText: printTextExpense = { summary: "", detail: "" };

  switch (idExpenseType) {
    case 1:
      printText.summary = t("Common.Term.Refund");
      printText.detail = t("Common.Term.BalanceRefund");
      break; // N'oubliez pas de mettre un "break" pour éviter la chute à travers les cas suivants.

    case 2:
      printText.summary = t("Common.Term.DepositFee");
      printText.detail = t("Common.Term.DepositFeeByOrganizer");
      break;

    case 3:
      printText.summary = t("Common.Term.RefundFee");
      printText.detail = t("Common.Term.RefundFeeByOrganizer");
      break;

    case 4:
      printText.summary = t("Common.Term.VoucherUsage");
      printText.detail = t("Common.Term.UsageOfVoucher");
      break;

    default:
      printText.summary = "Unknown";
      printText.detail = "Unknown";
  }

  return printText;
};
