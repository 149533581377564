import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import "react-phone-number-input/style.css";
import { useSelector } from "react-redux";
import { CompleteState } from "../../Store/CompleteReducer";
import "./FormDeposit.styles.css";
import { useAppDispatch } from "../../Store";
import { updateUiState } from "../../Store/actions/ui/ui";
import FastDepositButton from "./FastDepositButton/FastDepositButton";
import { createDeposit } from "../../Api/Deposit/DepositHelper";
import PaymentMethodIcons from "../PaymentMethodIcons/PaymentMethodIcons";
import { t } from "i18next";

const MIN_AMOUNT = 1;
const MAX_AMOUNT = 2000;

function FormDeposit() {
  const dispatch = useAppDispatch();
  const open = useSelector(
    (state: CompleteState) => state.ui.formDepositAmount
  );
  const firstDepositFees = useSelector(
    (state: CompleteState) => state.event.firstDepositFee
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [amount, setAmount] = useState<number | "">("");

  const strap = useSelector((state: CompleteState) => state.strap);

  const makeDeposit = () => {
    setLoading(true);
    if (
      strap.id !== undefined &&
      strap.idEncrypted !== undefined &&
      amount !== ""
    ) {
      createDeposit(strap.id, amount * 100, strap.idEncrypted)
        .then((response) => {
          window.location.href = response.paymentLink;
          setLoading(false);
        })
        .catch((err) => setLoading(false));
    }
  };

  const handleFastAmount = (value: number) => {
    // Vérifier si la valeur est un nombre valide entre 1 et 5000
    if (!isNaN(value) && value >= MIN_AMOUNT && value <= MAX_AMOUNT) {
      setAmount(value);
    } else {
      setAmount("");
    }
    setError(false);
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputAmount = parseInt(event.target.value);
    if (!isNaN(inputAmount)) {
      setAmount(inputAmount);

      if (inputAmount < MIN_AMOUNT || inputAmount > MAX_AMOUNT) {
        setError(true);
      } else {
        setError(false);
      }
    } else {
      setAmount("");
    }
  };

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>{t("Balance.Deposit.MakeADeposit")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {firstDepositFees && firstDepositFees > 0 ? (
              <Alert severity="info">
                {t("Balance.Deposit.OrganizerTakeMoney", {
                  amount: (firstDepositFees / 100).toFixed(2),
                })}
              </Alert>
            ) : null}
            <FormControl fullWidth sx={{ m: 1 }}>
              <>
                <TextField
                  id="standard-basic"
                  // label="Entrer le montant"
                  variant="standard"
                  style={{ marginBottom: "15px" }}
                  InputProps={{
                    style: {
                      fontSize: "2rem",
                      textAlign: "center",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                      textAlignLast: "center",
                    },
                  }}
                  onChange={handleAmountChange}
                  value={amount}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*", // Pour les appareils mobiles
                  }}
                  placeholder={t("Balance.Deposit.OtherAmount")}
                  helperText={
                    error
                      ? t("Balance.Deposit.AmountMinAndMax", {
                          min: MIN_AMOUNT,
                          max: MAX_AMOUNT,
                        })
                      : ""
                  }
                  error={error}
                />

                <div
                  style={{
                    display: "flex",
                    gap: "6px",
                    justifyContent: "space-evenly",
                  }}
                >
                  <FastDepositButton
                    value={20}
                    onAmountChange={handleFastAmount}
                  />
                  <FastDepositButton
                    value={50}
                    onAmountChange={handleFastAmount}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "6px",
                    justifyContent: "space-evenly",
                    marginTop: "6px",
                  }}
                >
                  <FastDepositButton
                    value={100}
                    onAmountChange={handleFastAmount}
                  />
                  <FastDepositButton
                    value={200}
                    onAmountChange={handleFastAmount}
                  />
                </div>
              </>
            </FormControl>
          </DialogContentText>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          ></div>
          <div style={{ textAlign: "center", paddingTop: "10px" }}>
            <PaymentMethodIcons />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              dispatch(updateUiState({ formDepositAmount: false }))
            }
            disabled={loading}
          >
            {t("Common.Abort")}{" "}
          </Button>
          <Button
            variant="contained"
            onClick={makeDeposit}
            disabled={loading || error || amount === ""}
          >
            {loading ? <CircularProgress size={15} /> : t("Common.Continue")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormDeposit;
