export const styleUsefulLinksContainer = {
  background:
    "linear-gradient(135deg, rgb(255 255 255) 10%, rgb(255 255 255) 100%)",
  padding: "10px",
  borderRadius: "0px 0 10px 10px",
  boxShadow:
    "rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px",
  display: "flex",
  justifyContent: "center",
  rowGap: "0.2rem",
  columnGap: "0.8rem",
  margin: "10px -10px -10px -10px",
  color: "black",
  flexFlow: "wrap",
  fontSize: "0.8rem",
};

export const styleUsefulLinksItem = {
  display: "flex",
  gap: "4px",
  alignItems: "center",
};
