import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  ExpensesState, ExpensesStateCommand } from "./expenses.types";

const initialState: ExpensesState = {
  expenses: [],
};

const expenses = createSlice({
  name: "expenses",
  initialState,
  reducers: {
    updateExpenses(state: ExpensesState, action: PayloadAction<ExpensesStateCommand[]>) {
      state.expenses = action.payload
    },
  },
});

export const { updateExpenses} = expenses.actions;
export default expenses.reducer;
