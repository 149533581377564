import { CSSProperties } from "react";

export const stylePaymentMethodIcon: CSSProperties = {
  maxWidth: "30px",
  borderRadius: "3px",
};

export const stylePaymentMethodContainer: CSSProperties = {
  display: "flex",
  alignItems: "stretch",
  justifyContent: "center",
  gap: "3px",
};

export const styleNoTransactionContainer: CSSProperties = {
  textAlign: "center",
};
