import { Button } from '@mui/material';
import React from 'react'

interface FastDepositButtonProps {
    value: number;
    onAmountChange: (value: number) => void; // Prop pour recevoir la référence de handleFastAmount
  }

function FastDepositButton(props : FastDepositButtonProps) {
    const handleButtonClick = () => {
        // Appeler la méthode handleFastAmount avec la valeur du montant du bouton
        props.onAmountChange(props.value);
      };
    
      return (
        <Button
          onClick={handleButtonClick}
          style={{ border: "1px solid grey", width: "100%" }}
        >
          {props.value} CHF
        </Button>
      );
}

export default FastDepositButton