import { CSSProperties } from "react";

export const styleTransactionItemContainerMainContainer: CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "5px 0px",
};

export const styleTransactionItemContainerSecondaryContainer: CSSProperties = {
  display: "flex",
  gap: "1rem",
  alignItems: "center",
};

export const styleTransactionItemIcon: CSSProperties = {
  borderRadius: "100%",
  background: "linear-gradient(135deg, rgb(8, 82, 251) 10%, rgb(9, 167, 250))",
  height: "35px",
  width: "35px",
};

export const styleTransactionItemDate: CSSProperties = {
  fontSize: "8px",
};

export const styleTransactionItemCurrency: CSSProperties = {
  ...styleTransactionItemDate,
  textAlign: "right",
};

export const styleTransactionItemLabel: CSSProperties = {
  fontWeight: "600",
};

export const styleTransactionItemAmountDefault: CSSProperties = {
  //DO NOT USE
  fontWeight: "700",
  padding: "1px 2px",
};

export const styleTransactionItemAmountPositive: CSSProperties = {
  ...styleTransactionItemAmountDefault,
  background: "linear-gradient(135deg, rgb(8, 82, 251) 10%, rgb(9, 167, 250))",
  color: "white",
  borderRadius: "2px",
  padding: "1px 4px",
};

export const styleTransactionItemAmountNegative: CSSProperties = {
  ...styleTransactionItemAmountDefault,
};

