import React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  styleUsefulLinksContainer,
  styleUsefulLinksItem,
} from "./UsefulLinks.styles";
import { useSelector } from "react-redux";
import { CompleteState } from "../../Store/CompleteReducer";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { EventLinkState } from "../../Store/actions/event/event.types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import Moment from "react-moment";
import { getEndRefundDate, isRefundActive } from "../../Utils.logik";
import { useTranslation } from "react-i18next";
import Tickets from "../Tickets/Tickets";

function UsefulLinks() {
  const { t } = useTranslation();

  const usefulLinks = useSelector(
    (state: CompleteState) => state.event?.eventLinks
  );

  const idStrap = useSelector(
    (state: CompleteState) => state.strap.idEncrypted
  );
  const deposits = useSelector(
    (state: CompleteState) => state.deposits.deposits
  );

  const event = useSelector((state: CompleteState) => state.event);

  let refundsActive = isRefundActive(event ? event.endDateTime : undefined);
  let endRefundDate = getEndRefundDate(event ? event.endDateTime : undefined);

  const [popUpRefundOpen, setPopUpRefundOpen] = React.useState(false);
  const [popUpBuyTicketsOpen, setPopUpBuyTicketsOpen] = React.useState(false);

  const getIconFromEnum = (icon: string) => {
    switch (icon) {
      case "HelpOutline":
        return <HelpOutlineIcon fontSize="small" />;
      case "FormatListBulleted":
        return <FormatListBulletedIcon fontSize="small" />;
      case "FormatListNumbered":
        return <FormatListNumberedIcon fontSize="small" />;
      case "ListAlt":
        return <ListAltIcon fontSize="small" />;
      case "LocationOn":
        return <LocationOnIcon fontSize="small" />;
      default:
        return <HelpOutlineIcon fontSize="small" />;
    }
  };

  const handleClickOpenLink = (item: EventLinkState) => {
    if (item.id < 0) {
      switch (item.id) {
        case -1:
          setPopUpRefundOpen(true);
          break;
        case -2:
          setPopUpBuyTicketsOpen(true);
          break;
      }
    } else {
      window.open(item.uri, "_blank");
    }
  };

  const definePreferedTypeOfRefund = () => {
    const cash = deposits.some((deposit) => deposit.idDepositType === 2);
    const electronic = deposits.some((deposit) => deposit.idDepositType !== 2);

    if (electronic) {
      return "electronic";
    } else if (cash) {
      return "cash";
    } else {
      return "";
    }
  };

  const refundTypeRecommended = definePreferedTypeOfRefund();
  const renderBadgeForYou = (
    <span
      style={{
        fontSize: "0.8rem",
        marginLeft: "5px",
        borderRadius: "20px",
        padding: "4px 8px",
        backgroundColor: "#66bb6a",
        color: "white",
      }}
    >
      {t("Refund.Sentence.ForYou")}
    </span>
  );

  return (
    <div style={styleUsefulLinksContainer}>
      {usefulLinks?.map((item, index) => (
        // eslint-disable-next-line
        <a
          key={index}
          href="#"
          onClick={() => handleClickOpenLink(item)}
          style={{ textDecoration: "none", color: "initial" }}
          rel="noreferrer"
        >
          <div style={styleUsefulLinksItem}>
            <div className="icon">{getIconFromEnum(item.icon)}</div>
            <div className="label" style={{ marginBottom: "3px" }}>
              {item.title}
            </div>
          </div>
        </a>
      ))}
      {/* Refund pop-up */}
      <Dialog
        open={popUpRefundOpen}
        onClose={() => setPopUpRefundOpen(false)}
        fullScreen
      >
        <DialogTitle textAlign={"center"}>
          <b>{t("Refund.Explanation.Title")}</b>
        </DialogTitle>
        <DialogContent>
          {event.withDrawalFee && (
            <Alert severity="info" style={{ marginBottom: "10px" }}>
              {t("Refund.OrganisationFees.Text", {
                fee: event.withDrawalFee / 100,
              })}
            </Alert>
          )}
          <DialogContentText>
            <Accordion>
              <AccordionSummary
                expandIcon={<IoIosArrowDown />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>
                  {t("Refund.Automatic.Title")} 🚀
                  {refundTypeRecommended === "electronic"
                    ? renderBadgeForYou
                    : null}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontSize: "0.9rem" }}>
                  {t("Refund.Automatic.Description")} :{" "}
                  <i>Twint, Visa, MasterCard, ApplePay et SamsungPay</i>.
                  <br></br>
                  <br></br>
                  {t("Refund.Automatic.Delay.Description")}{" "}
                  <i>
                    <b>{t("Refund.Automatic.Delay.Twint")}</b>
                  </i>
                  , {t("Refund.Automatic.Delay.OnlineDeposit")}{" "}
                  <b>
                    <i>{t("Refund.Automatic.Delay.5To9Days")}</i>
                  </b>{" "}
                  {t("Refund.Automatic.Delay.EndOnlineDeposit")}
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<IoIosArrowDown />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>
                  {t("Refund.Manual.Title")} 💰
                  {refundTypeRecommended === "cash" ? renderBadgeForYou : null}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontSize: "0.9rem" }}>
                  {t("Refund.Manual.Delay.Begin")}{" "}
                  <b>{t("Refund.Manual.Delay.byOrganizer")}</b>{" "}
                  {t("Refund.Manual.Delay.EndSentence")}
                  <br></br>
                  <br></br>
                  <Button
                    href={`${window.location.origin}/refund/${idStrap}`}
                    target="_blank"
                    size="small"
                    variant="outlined"
                    disabled={!refundsActive}
                  >
                    {t("Refund.Button.GoToRefund")}
                  </Button>
                  <div>
                    {event.endDateTime ? (
                      <div style={{ marginTop: "10px" }}>
                        <div style={{ fontSize: "0.8rem" }}>
                          {t("Refund.ClosingDate")}
                        </div>
                        <Moment
                          style={{ fontWeight: "bolder" }}
                          format="D MMMM YYYY HH:mm"
                        >
                          {endRefundDate}
                        </Moment>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <br></br><b>Adresse e-mail liée au bracelet :</b>{" "}
                    <i>{email ? anonymizeEmail(email) : "aucune"}</i> */}
                </div>
              </AccordionDetails>
            </Accordion>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setPopUpRefundOpen(false)}>
            {t("Common.Close")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Buy tickets pop-up */}
      <Dialog
        open={popUpBuyTicketsOpen}
        onClose={() => setPopUpBuyTicketsOpen(false)}
        fullScreen
      >
        <DialogTitle textAlign={"center"}>
          <b>{t("BuyTickets.Explanation.Title")}</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Tickets setModaleOpen={setPopUpBuyTicketsOpen}></Tickets>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setPopUpBuyTicketsOpen(false)}
          >
            {t("Common.Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UsefulLinks;
