import Skeleton from "@mui/material/Skeleton/Skeleton";
import React from "react";
import Footer from "../Footer/Footer";

function Loading() {
  return (
    <>
      <div
        style={{
          margin: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Skeleton animation="wave" width={window.innerWidth * 0.3} />
            <Skeleton animation="wave" width={window.innerWidth * 0.6} />
          </div>
          <div>
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          </div>
        </div>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={140}
          style={{ margin: "20px 0 10px 0" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Skeleton
            animation="wave"
            variant="rounded"
            width={window.innerWidth * 0.45}
            height={50}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            width={window.innerWidth * 0.45}
            height={50}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row-reverse",
              gap: "12px",
            }}
          >
            <div>
              <Skeleton animation="wave" width={window.innerWidth * 0.2} />
              <Skeleton animation="wave" width={window.innerWidth * 0.4} />
            </div>
            <div>
              <Skeleton
                animation="wave"
                variant="circular"
                width={40}
                height={40}
              />
            </div>
          </div>
          <Skeleton animation="wave" variant="rounded" width={63} height={23} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row-reverse",
              gap: "12px",
            }}
          >
            <div>
              <Skeleton animation="wave" width={window.innerWidth * 0.2} />
              <Skeleton animation="wave" width={window.innerWidth * 0.4} />
            </div>
            <div>
              <Skeleton
                animation="wave"
                variant="circular"
                width={40}
                height={40}
              />
            </div>
          </div>
          <Skeleton animation="wave" variant="rounded" width={63} height={23} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row-reverse",
              gap: "12px",
            }}
          >
            <div>
              <Skeleton animation="wave" width={window.innerWidth * 0.2} />
              <Skeleton animation="wave" width={window.innerWidth * 0.4} />
            </div>
            <div>
              <Skeleton
                animation="wave"
                variant="circular"
                width={40}
                height={40}
              />
            </div>
          </div>
          <Skeleton animation="wave" variant="rounded" width={63} height={23} />
        </div>
        <Footer idStrap={''} eventTitle=""/>
      </div>
    </>
  );
}

export default Loading;
