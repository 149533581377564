
// Redux dependencies
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import CompleteReducer from "./CompleteReducer";

// Define global store
const store = configureStore({
  reducer: CompleteReducer,
  devTools: process.env.NODE_ENV !== "production",
});

function configurableStore(preloadedState: any) {
  return configureStore({
    reducer: CompleteReducer,
    devTools: process.env.NODE_ENV !== "production",
    preloadedState: preloadedState,
  });
}

// Utilities to types redux
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
export { store, configurableStore };
