import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventLinkState, EventStateCommand } from "./event.types";

const initialState: EventStateCommand = {
  id: undefined,
  title: undefined,
  email: undefined,
  beginDateTime: undefined,
  endDateTime: undefined,
  beginDepositDateTime : undefined,
  description: undefined,
  strapMockupUrl: undefined,
  withDrawalFee: undefined,
  minimumAmountToWithDraw: undefined,
  idEnterprise: undefined,
  eventLinks: [],
  firstDepositFee:undefined
};

const event = createSlice({
  name: "event",
  initialState,
  reducers: {
    updateEvent(
      state: EventStateCommand,
      action: PayloadAction<EventStateCommand>
    ) {
      if (action.payload.id !== undefined) {
        state.id = action.payload.id;
      }
      if (action.payload.title !== undefined) {
        state.title = action.payload.title;
      }
      if (action.payload.email !== undefined) {
        state.email = action.payload.email;
      }
      if (action.payload.beginDateTime !== undefined) {
        state.beginDateTime = action.payload.beginDateTime;
      }
      if (action.payload.endDateTime !== undefined) {
        state.endDateTime = action.payload.endDateTime;
      }
      if (action.payload.beginDepositDateTime !== undefined) {
        state.beginDepositDateTime = action.payload.beginDepositDateTime;
      }
      if (action.payload.description !== undefined) {
        state.description = action.payload.description;
      }
      if (action.payload.strapMockupUrl !== undefined) {
        state.strapMockupUrl = action.payload.strapMockupUrl;
      }
      if (action.payload.withDrawalFee !== undefined) {
        state.withDrawalFee = action.payload.withDrawalFee;
      }
      if (action.payload.minimumAmountToWithDraw !== undefined) {
        state.minimumAmountToWithDraw = action.payload.minimumAmountToWithDraw;
      }
      if (action.payload.idEnterprise !== undefined) {
        state.idEnterprise = action.payload.idEnterprise;
      }

      if (action.payload.firstDepositFee !== undefined) {
        state.firstDepositFee = action.payload.firstDepositFee;
      }

      if (action.payload.eventLinks !== undefined) {
        state.eventLinks = action.payload.eventLinks;
      }
    },
    //method for reset state
    resetEventState(state: EventStateCommand) {
      state.id = initialState.id;
      state.title = initialState.title;
      state.email = initialState.email;
      state.beginDateTime = initialState.beginDateTime;
      state.endDateTime = initialState.endDateTime;
      state.description = initialState.description;
      state.strapMockupUrl = initialState.strapMockupUrl;
      state.withDrawalFee = initialState.withDrawalFee;
      state.minimumAmountToWithDraw = initialState.minimumAmountToWithDraw;
      state.idEnterprise = initialState.idEnterprise;
      state.eventLinks = initialState.eventLinks;
      state.firstDepositFee = initialState.firstDepositFee
    },

    addEventLink(
      state: EventStateCommand,
      action: PayloadAction<EventLinkState>
    ) {
      //Ajoute un lien au début de la liste des liens
      state.eventLinks?.unshift(action.payload)
    },
  },
});

export const { updateEvent, resetEventState,addEventLink} = event.actions;
export default event.reducer;
