import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import {
  styleStrapInformationsDialogContentContainer,
  styleStrapInformationsDialogTitle,
} from "../../StrapInformations/StrapInformations.styles";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import VoucherItem from "./VoucherItem/VoucherItem";
import Button from "@mui/material/Button/Button";
import Divider from "@mui/material/Divider/Divider";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { CompleteState } from "../../../Store/CompleteReducer";
import { getVouchersFromStrap } from "../../../Api/Voucher/VoucherHelper";
import { RiCoupon3Line } from "react-icons/ri";
import { t } from "i18next";
import VoucherStateCommand from "../../../Store/actions/vouchers/vouchers.types";

function Voucher() {
  const [open, setOpen] = React.useState(false);
  const strap = useSelector((state: CompleteState) => state.strap);
  const vouchers = useSelector(
    (state: CompleteState) => state.vouchers.vouchers
  );

  useEffect(() => {
    if (strap.id) {
      //Retrieve vouchers of this strap
      getVouchersFromStrap(strap.id).then(() => {});
    }
  }, [strap.id]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getVouchersDictionary = (
    items: VoucherStateCommand[]
  ): Record<number, number> => {
    const count: Record<number, number> = {};
    for (const item of items) {
      count[item.idVoucher] = (count[item.idVoucher] || 0) + 1;
    }
    return count;
  };

  const removeDuplicateVouchers = (
    vouchers: VoucherStateCommand[]
  ): VoucherStateCommand[] => {
    const seen: Record<number, boolean> = {};
    return vouchers.filter((voucher) => {
      if (seen[voucher.idVoucher]) {
        return false;
      }
      seen[voucher.idVoucher] = true;
      return true;
    });
  };

  const availableVouchersDictionnary = getVouchersDictionary(
    vouchers.filter((v) => v.isConsumed === false)
  );
  const availableVouchers = vouchers.filter((v) => v.isConsumed === false);
  const usedVouchers = vouchers
    .filter((v) => v.isConsumed === true)
    .sort(
      (a, b) =>
        new Date(b.updatedDateTime).getTime() -
        new Date(a.updatedDateTime).getTime()
    );

  return (
    <>
      {vouchers.length > 0 ? (
        <div className="VoucherZone">
          <div
            style={{
              alignItems: "center",
              gap: "6px",
              //padding: "8px 0",
              display: "inline-flex",
              //border: "1px solid white",
              //padding: "4px 6px",
              borderRadius: "9px",
              scale: "0.9",
              marginLeft: "-10px",
            }}
            onClick={handleClickOpen}
          >
            {/* <LocalActivityOutlinedIcon fontSize="small"></LocalActivityOutlinedIcon> */}
            <div>{t("Voucher.YourVouchers")}</div>
            <div
              style={{
                fontSize: "0.65rem",
                border: "1px solid white",
                padding: "2px 4px",
                borderRadius: "6px",
              }}
            >
              {vouchers.filter((e) => e.isConsumed === false).length}/
              {vouchers.length}
            </div>
          </div>
          <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle sx={styleStrapInformationsDialogTitle}>
              <div
                style={{
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <RiCoupon3Line size={"20px"} />
                <span>{t("Voucher.YourVouchers")}</span>
              </div>
            </DialogTitle>
            <DialogContent sx={styleStrapInformationsDialogContentContainer}>
              {/* <div className="VoucherGroup"> */}
              <div
                className="available-vouchers"
                style={{
                  display: availableVouchers.length === 0 ? "none" : "initial",
                }}
              >
                <Box textAlign={"left"} fontSize={"0.8rem"}>
                  {`${t("Common.Available", { 0: availableVouchers.length })}`}
                </Box>
                <Box
                  textAlign={"center"}
                  alignItems={"center"}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"10px"}
                  margin={"10px 0"}
                >
                  {removeDuplicateVouchers(availableVouchers).map(
                    (v, index) => (
                      <VoucherItem
                        key={`${v.id}-${index}`}
                        id={v.id}
                        idStrap={v.idStrap}
                        idVoucher={v.idVoucher}
                        voucher={{
                          title: v.voucher.title,
                          product: v.voucher.product,
                          productSection: v.voucher.productSection,
                          minPrice: v.voucher.minPrice,
                          maxPrice: v.voucher.maxPrice,
                        }}
                        description={v.description}
                        isConsumed={v.isConsumed}
                        updatedDateTime={v.updatedDateTime}
                        quantity={availableVouchersDictionnary[v.idVoucher]}
                      ></VoucherItem>
                    )
                  )}
                </Box>
              </div>
              <div
                className="used-vouchers"
                style={{
                  display: usedVouchers.length === 0 ? "none" : "initial",
                }}
              >
                <Box textAlign={"left"} fontSize={"0.8rem"}>
                  {t("Voucher.Common.Used", { 0: usedVouchers.length })}
                </Box>
                <Box
                  textAlign={"center"}
                  alignItems={"center"}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"10px"}
                  margin={"10px 0"}
                >
                  {usedVouchers.map((v, index) => (
                    <VoucherItem
                      key={`${v.id}-${index}`}
                      id={v.id}
                      idStrap={v.idStrap}
                      idVoucher={v.idVoucher}
                      voucher={{
                        title: v.voucher.title,
                        product: v.voucher.product,
                        productSection: v.voucher.productSection,
                        minPrice: v.voucher.minPrice,
                        maxPrice: v.voucher.maxPrice,
                      }}
                      description={v.description}
                      isConsumed={v.isConsumed}
                      updatedDateTime={v.updatedDateTime}
                    ></VoucherItem>
                  ))}
                </Box>
              </div>
              <Divider></Divider>
            </DialogContent>
            <DialogActions>
              <Button size="small" onClick={handleClose}>
                {t("Common.Close")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
    </>
  );
}

export default Voucher;
