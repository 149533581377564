import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DepositsState, DepositStateCommand } from "./deposits.types";

const initialState: DepositsState = {
  deposits: [],
};

const deposits = createSlice({
  name: "orders",
  initialState,
  reducers: {
    updateDeposits(state: DepositsState, action: PayloadAction<DepositStateCommand[]>) {
      state.deposits = action.payload
    },
  },
});

export const { updateDeposits} = deposits.actions;
export default deposits.reducer;
