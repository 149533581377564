import { Alert, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { strapExist } from "../../Api/Strap/StrapHelper";
import axios from "axios";
import { URL_API } from "../../Api/config";

function HomePage() {
  const [strapId, setStrapId] = useState("");
  const [isWorking, setIsWorking] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${URL_API}/events`).catch((error) => {
      setIsWorking(false);
    });
  }, []);

  // Fonction appelée lorsqu'une touche est pressée
  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter") {
      handleClickStrapNumber();
    }
  };

  // Fonction appelée lorsqu'on clique sur le bouton de validation
  async function handleClickStrapNumber() {
    strapExist(strapId).then((response: boolean) => {
      if (response) {
        navigate(`/${strapId}`);
      } else {
        toast.error("Le bracelet n'existe pas");
      }
    });
  }

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        limit={2}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        className="Header"
        style={{
          width: "90%",
          textAlign: "center",
          margin: "20px 5%",
        }}
      >
        <img
          src="logo_snp.svg"
          alt="logo strapnpay"
          style={{ width: "50vw" }}
        ></img>
        <div style={{ fontSize: "0.8rem" }}>
          La solution 🇨🇭 pour vos événements cashless.
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          gap: "5px",
          justifyContent: "center",
          padding: "20px 0",
        }}
      >
        <TextField
          id="outlined-basic"
          label="Numéro du bracelet"
          variant="outlined"
          size="small"
          value={strapId}
          placeholder="Mettre '123'"
          onChange={(e) => setStrapId(e.target.value)}
          style={{ minWidth: "195px" }}
          onKeyDown={handleKeyDown}
        />
        <div
          style={{
            background: "#8958FE",
            color: "white",
            height: "24px",
            borderRadius: "50%",
          }}
          onClick={handleClickStrapNumber}
        >
          <ChevronRightIcon />
        </div>
      </div>
      <Stack sx={{ width: "90%",margin:'0 5%' }}>
        {isWorking ? (
          <Alert variant="filled" severity="success">
            Le système est opérationnel
          </Alert>
        ) : (
          <Alert variant="filled" severity="error">
            Un problème majeur est en cours
          </Alert>
        )}
      </Stack>
      <Footer idStrap={''} eventTitle=""></Footer>
    </>
  );
}

export default HomePage;
