export function convertToGMTPlus1(date: Date) {
  const gmt0Time = date.getTime(); // Obtenez le temps en GMT+0 (en millisecondes)
  const gmtPlus2Time = gmt0Time + 1 * 60 * 60 * 1000; // Ajoutez 1 heures en millisecondes

  const gmtPlus2Date = new Date(gmtPlus2Time); // Créez un nouvel objet Date en GMT+2
  return gmtPlus2Date;
}

//Nombre jour maximal pour demander un remboursement
export const REFUND_MAXDAYS = 20;

export const isRefundActive = (eventEndDate: Date | undefined) => {
  if (eventEndDate) {
    const endDatePlus20Days = new Date(convertToGMTPlus1(new Date(eventEndDate)));
    endDatePlus20Days.setDate(endDatePlus20Days.getDate() + REFUND_MAXDAYS);
    return new Date() < endDatePlus20Days;
  } else {
    return false;
  }
};

export const getEndRefundDate = (eventEndDate: Date | undefined) => {
  if (eventEndDate) {
    const endDatePlus20Days = new Date(
      convertToGMTPlus1(new Date(eventEndDate))
    );
    const endRefundDate = endDatePlus20Days.setDate(
      endDatePlus20Days.getDate() + REFUND_MAXDAYS
    );

    return endRefundDate;
  } else {
    return 0;
  }
};
