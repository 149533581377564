import axios, { AxiosRequestConfig } from "axios";
import { URL_API } from "../config";
import { DepositStateCommand, DepositType } from "./DepositHelper.types";

/**
 *
 * @param idStrap strap id
 * @returns
 */
export const createDeposit = (
  idStrap: string,
  amount: number,
  idEncryptedStrap: string
): Promise<DepositStateCommand> => {
  const config: AxiosRequestConfig = {
    method: 'post',
    url: `${URL_API}/deposits`,
    headers: {
      'X-Strap-Encrypted-Identifier': idStrap,
    },
  };

  const data = {
    idStrapHashed: idStrap,
    amount: amount,
    redirectUrl: `${window.location.origin}/${idEncryptedStrap}`,
  };

  return axios.post(config.url?? '', data, config)
    .then((response) => response.data.data)
    .catch((error) => {
      // Gérer les erreurs ici si nécessaire
      console.error('Une erreur s\'est produite lors de la création du dépôt.', error);
      throw error; // Vous pouvez choisir de relancer l'erreur ou de la gérer d'une autre manière
    });
};

export const getDepositTypeById = (
  idDepositType: number
): Promise<DepositType> => {
  const config = {
    url: `${URL_API}/deposit-Types/${idDepositType}`,
    method: "get",
  };

  return axios(config).then((response) => {
    return response.data.data;
  });
};
