import React from "react";
import "./MessageDeposit.styles.css";
import PaymentMethodIcons from "../PaymentMethodIcons/PaymentMethodIcons";
import { styleNoTransactionContainer } from "./MessageDeposit.styles";
import DepositButton from "../DepositButton/DepositButton";
import { useTranslation } from "react-i18next"; // Importez useTranslation ici


function MessageDeposit() {
  const { t } = useTranslation(); // Obtenez la fonction t ici pour la traduction

  return (
    <div style={styleNoTransactionContainer}>
      <div>{t('Transactions.MakeFirstDepost.Title')}</div>
      <DepositButton />
      <PaymentMethodIcons />
    </div>
  );
}

export default MessageDeposit;
