import axios from "axios";
import { URL_API, config } from "../config";
import store from "../../Store";
import { updateVouchers } from "../../Store/actions/vouchers/vouchers";
import VoucherStateCommand from "../../Store/actions/vouchers/vouchers.types";


export const getVouchersFromStrap = (idStrap: string): Promise<VoucherStateCommand[]> => {
  config.url = `${URL_API}/strap-vouchers?idStrap=${idStrap}`;
  config.method = 'get'
  return axios(config).then((response) => {
    store.dispatch(updateVouchers(response.data.data))
    return response.data.data;
  });
};
