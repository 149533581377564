import axios from "axios";
import { OrderStateCommand } from "../../Store/actions/orders/orders.types";
import { URL_API, config } from "../config";

/**
 *
 * @param idStrap strap id
 * @returns
 */
export const getOrderFromStrapId = (
  idStrap: string
): Promise<OrderStateCommand[]> => {
  if(idStrap.length < 10)
    return getOrderFromStrapBasicId(idStrap);
  else
  return getOrderFromStrapEncryptedId(idStrap);
};

const getOrderFromStrapBasicId = (
  idStrap: string
): Promise<OrderStateCommand[]> => {
  config.url = `${URL_API}/orders?idStrap=${idStrap}`;
  return axios(config).then((response) => {
    return response.data.data;
  });
};

const getOrderFromStrapEncryptedId = (
  idStrap: string
): Promise<OrderStateCommand[]> => {
  config.url = `${URL_API}/orders?idEncryptedStrap=${idStrap}`;
  return axios(config).then((response) => {
    return response.data.data;
  });
};


export const preOrder = (
  idStrap: string,
  pinCode : string,
  idSalePoint : number,
  products : {}
): Promise<any> => {

  const config = {
    method: 'post',
    url: `${URL_API}/orders/pre-order`,
    headers: {
      'X-Strap-Encrypted-Identifier': idStrap,
    },
    data: {
      idStrapEncrypted: idStrap,
      pinCode: pinCode,
      idSalePoint: idSalePoint,
      products: products
    }
  };
  return axios(config,).then((response) => {
    return response.data.data;
  });
};