import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UiState, { UiStateCommand } from './ui.types';

//Défini l'état initial
const initialState: UiState = {
    formPersonalInfoIsOpen: false,
    formDepositAmount: false,
};

const ui = createSlice({
    name: 'ui', //Défini le nom du reducer
    initialState, //Défini son état initial
    reducers: { 
        //Fonctions
        updateUiState(state: UiState, action: PayloadAction<UiStateCommand>) {
            if (action.payload.formPersonalInfoIsOpen !== undefined) {
                state.formPersonalInfoIsOpen = action.payload.formPersonalInfoIsOpen;
            }

            if (action.payload.formDepositAmount !== undefined) {
                state.formDepositAmount = action.payload.formDepositAmount;
            }
        },
    },
});

export const { updateUiState } = ui.actions;
export default ui.reducer;