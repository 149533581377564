import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrdersState, OrderStateCommand } from "./orders.types";

const initialState: OrdersState = {
  orders: [],
};

const orders = createSlice({
  name: "orders",
  initialState,
  reducers: {
    updateOrder(state: OrdersState, action: PayloadAction<OrderStateCommand[]>) {
      state.orders = action.payload
    },
  },
});

export const { updateOrder} = orders.actions;
export default orders.reducer;
