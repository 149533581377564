import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";


function printVersionNumber() {
  if (window.location.href.indexOf("test.") > -1) {
    return "test version";
  } else {
    return `v${process.env.REACT_APP_VERSION}`;
  }
}

interface FooterProps {
  idStrap: string;
  eventTitle: string;
}

export const getLinkForWhatsapp = (idStrap : string, eventTitle : string) => {
  const noText = idStrap === "" && eventTitle === "";
  
  let fullLink = "https://api.whatsapp.com/send/?phone=41766715300";
  if (!noText) {
    fullLink =
      fullLink.concat(`&text=*%C3%89crivez%20votre%20message%20ici*%0A%0A--------%0A%C3%89v%C3%A9nement%20%3A%20${eventTitle}%0ABracelet%20%3A%20${idStrap}`)
  }
  return fullLink
};

function Footer(props: FooterProps) {
  const { t } = useTranslation();

  return (
    <footer
      style={{
        textAlign: "center",
        marginTop: "30px",
        fontSize: "0.8rem",
      }}
    >
      <div>
        © {new Date().getFullYear()} Strap'nPay Sàrl ({printVersionNumber()}). 
        {t('Footer.AllRights')}
      </div>
      <div>
        {t('Footer.CreatedBy')}{" "}
        <a
          style={{
            textDecoration: "none",
            color: "#8958fe",
          }}
          href="https://strapnpay.ch"
          target="_blank"
          rel="noreferrer"
        >
          <b>Strap'nPay Sàrl. </b>
        </a>
        (
        <a
          href={getLinkForWhatsapp(props.idStrap, props.eventTitle)}
          style={{
            textDecoration: "none",
            color: "#8958fe",
            fontWeight:"bold"
          }}
        >
          {t('Footer.NeedHelp')}  ?
        </a>
        )
      </div>
    </footer>
  );
}

export default Footer;
