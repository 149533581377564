import { Box, Button, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoMdAdd, IoMdRemove } from "react-icons/io";

export interface SelectedTickets {
  [key: string]: number;
}

interface TicketSelectorProps {
  nextStep: () => void;
  onSelectedTicketsChange: (selectedTickets: SelectedTickets) => void;
  onTotalTicketsChange: (totalTickets: number) => void;
}

export const promoTicket = {
  id: 892,
  title: "Transport",
  description: "Trajet transport",
  price: 500,
  idSalePoint:96,
};

function TicketsSelector(props: TicketSelectorProps) {
  const { onSelectedTicketsChange, onTotalTicketsChange } = props;
  const [selectedTickets, setSelectedTickets] = useState<SelectedTickets>({});
  const [totalTickets, setTotalTickets] = useState<number>(0);

  // Calculate number of tickets
  useEffect(() => {
    const total = Object.values(selectedTickets).reduce(
      (acc, val) => acc + val,
      0
    );
    setTotalTickets(total);
    onSelectedTicketsChange(selectedTickets);
    onTotalTicketsChange(total);
  }, [selectedTickets, onSelectedTicketsChange, onTotalTicketsChange]);

  const handleIncrement = (id: number) => {
    setSelectedTickets((prevSelectedTickets) => {
      const newSelectedTickets = { ...prevSelectedTickets };
      newSelectedTickets[`${id}`] = (newSelectedTickets[`${id}`] || 0) + 1;
      return newSelectedTickets;
    });
  };

  const handleDecrement = (id: number) => {
    setSelectedTickets((prevSelectedTickets) => {
      const newSelectedTickets = { ...prevSelectedTickets };
      const currentQuantity = newSelectedTickets[`${id}`] || 0;

      if (currentQuantity > 0) {
        newSelectedTickets[`${id}`] = currentQuantity - 1;
      }

      return newSelectedTickets;
    });
  };

  return (
    <>
      <div
        key={promoTicket.id}
        style={{
          background: "#ececec4a",
          borderRadius: "10px",
          border: "1px solid gray",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          padding={"15px 20px"}
          borderRadius={"10px"}
          alignItems={"center"}
        >
          <Box display={"flex"} flexDirection={"column"}>
            <div style={{ fontWeight: "500" }}>{promoTicket.title}</div>
            <div style={{ fontSize: "0.7rem", color: "#9f9f9f" }}>
              {promoTicket.description}
            </div>
          </Box>
          <Box display={"flex"} gap={"5px"}>
            <Box textAlign={"right"}></Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-end"}
            >
              <Box display={"flex"} gap={"10px"} alignItems={"center"}>
                <>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    style={{ background: "#dddddd", color: "black" }}
                    onClick={() => handleDecrement(promoTicket.id)}
                  >
                    <IoMdRemove />
                  </IconButton>
                  <div>
                    <b>{selectedTickets[`${promoTicket.id}`] || 0}</b>
                  </div>
                  <IconButton
                    aria-label="add"
                    size="small"
                    style={{ background: "#dddddd", color: "black" }}
                    onClick={() => handleIncrement(promoTicket.id)}
                  >
                    <IoMdAdd />
                  </IconButton>
                </>
              </Box>
              <div style={{ fontSize: "0.7rem" }}>
                <b>{(promoTicket.price / 100).toFixed(2)} CHF</b>
              </div>
            </Box>
          </Box>
        </Box>
      </div>
      {totalTickets > 0 && (
        <div style={{textAlign:'right'}}>
          <Button
            variant="outlined"
            size="small"
            style={{ marginTop: "10px" }}
            onClick={props.nextStep}
          >
            Suivant
          </Button>
          .
        </div>
      )}
    </>
  );
}

export default TicketsSelector;
