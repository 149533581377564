import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import VoucherStateCommand, { VouchersState } from "./vouchers.types";


const initialState: VouchersState = {
    vouchers: [],
  };

  const vouchers = createSlice({ 
    name: "vouchers",
    initialState,
    reducers: {
      updateVouchers(state: VouchersState, action: PayloadAction<VoucherStateCommand[]>) {
        //Sort by updatedDateTime
        state.vouchers = action.payload.sort((a, b) => new Date(b.updatedDateTime).getTime() - new Date(a.updatedDateTime).getTime());
      },
    },
  });
  
  export const { updateVouchers} = vouchers.actions;
  export default vouchers.reducer;
  