import React, { useState } from "react";
import TicketsSelector, {
  SelectedTickets,
  promoTicket,
} from "./TicketsSelector/TicketsSelector";
import {
  Box,
  Button,
  Step,
  StepLabel,
  StepContent,
  Stepper,
  Grid,
} from "@mui/material";
import PinCodeInput from "../PinCodeInput/PinCodeInput";
import { t } from "i18next";
import { preOrder } from "../../Api/Order/OrderHelper";
import { useSelector } from "react-redux";
import { CompleteState } from "../../Store/CompleteReducer";
import Loading from "../Loading/Loading";
import { toast } from "react-toastify";

function Tickets(props: {setModaleOpen: (value: boolean) => void;}) {
  const [activeStep, setActiveStep] = useState(0);
  const [validPinCode, setValidPinCode] = useState('');
  const [selectedTickets, setSelectedTickets] = useState<SelectedTickets>({});
  const strap = useSelector((state: CompleteState) => state.strap);
  // eslint-disable-next-line
  const [totalTickets, setTotalTickets] = useState(0);
  const [loading, setLoading] = useState(false);

  const nextStep = () => {
    setActiveStep((currentStep) => {
      console.log("step " + (currentStep + 1));
      return currentStep + 1;
    });
  };

  const handleSelectedTicketsChange = (tickets: SelectedTickets) => {
    setSelectedTickets(tickets);
  };

  const handleTotalTicketsChange = (total: number) => {
    setTotalTickets(total);
  };

  const handleClickBuyTicket = () => {
    console.log(validPinCode)
    setLoading(true)
    preOrder(strap.id ? strap.id : '',validPinCode,promoTicket.idSalePoint,selectedTickets).then((r) => {
      console.log(r)
      props.setModaleOpen(false)
      document.getElementById('refresh-button')?.click()
      toast.success('Merci pour votre achat. Les tickets se trouvent dans "Vos coupons".')
    }).catch((e) => {
      const errorMessage = e.response.data.errors[''][0] as string;
      if (errorMessage.includes("not enough")) {
        toast.error('Solde insuffisant, veuillez recharger votre bracelet.', {
          position: "top-center",
        });
      } else {
        toast.error('Une erreur est survenue, réessayez plus tard.', {
          position: "top-center",
        });
      }
    }).finally(() => {
      setLoading(false)
    })
  };

  return (
    <>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key={"selector"}>
          <StepLabel>
            <strong>{t("Tickets.Step.Ticket")}</strong>
          </StepLabel>
          <StepContent>
            <TicketsSelector
              nextStep={nextStep}
              onSelectedTicketsChange={handleSelectedTicketsChange}
              onTotalTicketsChange={handleTotalTicketsChange}
            />
          </StepContent>
        </Step>
        <Step key={"pin"}>
          <StepLabel>{t("Tickets.Step.CodePin")}</StepLabel>
          <StepContent>
            {/* {strap.} */}
            <PinCodeInput onPinValid={nextStep} setValidPinCode={setValidPinCode}/>
          </StepContent>
        </Step>
        <Step key={"end"}>
          <StepLabel>{t("Tickets.Step.Confirme")}</StepLabel>
          <StepContent>
            <Box>
              <Grid container spacing={1}>
                {/* {JSON.stringify(selectedTickets)} */}
                <Grid item xs={4}>
                  <div style={{ marginBottom: "10px" }}>
                    <strong>{t("Tickets.Ticket.Label")}</strong>
                  </div>
                  <div>{promoTicket.title}</div>
                </Grid>
                <Grid item textAlign={"right"} xs={4}>
                  <div style={{ marginBottom: "10px" }}>
                    <strong>{t("Tickets.Qty.Label")}</strong>
                  </div>
                  <div>{selectedTickets[`${promoTicket.id}`] || 0}</div>
                </Grid>
                <Grid textAlign={"right"} item xs={4}>
                  <div style={{ marginBottom: "10px" }}>
                    <strong>{t("Tickets.Price.Label")}</strong>
                  </div>
                  <div>{(promoTicket.price / 100).toFixed(2)} CHF</div>
                </Grid>
              </Grid>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box textAlign={"right"}>
                  <strong>TOTAL</strong>
                </Box>
                <Box textAlign={"right"} >
                
                  <strong>
                    {(
                      (promoTicket.price *
                        selectedTickets[`${promoTicket.id}`]) /
                      100
                    ).toFixed(2)}{" "}
                    CHF
                  </strong>
                </Box>
              </Box>
              <Box textAlign={"center"} marginTop={"10px"}>
                <Button size="small" variant="outlined" onClick={() => handleClickBuyTicket()} disabled={loading}>
                {loading ? <Loading /> : (
                  <>
                    {t("Tickets.Confirm.Pay")}{" "}
                    {(
                      (promoTicket.price * selectedTickets[`${promoTicket.id}`]) /
                      100
                    ).toFixed(2)}{" "}
                    CHF
                  </>
                )}
                </Button>
              </Box>
            </Box>
          </StepContent>
        </Step>
      </Stepper>
    </>
  );
}

export default Tickets;
