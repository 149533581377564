import { SponsorState, SponsorStateCommand } from "./sponsors.types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: SponsorState = {
  sponsors: [],
};

const sponsors = createSlice({
  name: "sponsors",
  initialState,
  reducers: {
    updateSponsors(state: SponsorState,action: PayloadAction<SponsorStateCommand[]>) {
      state.sponsors = action.payload;
    },
  },
});

export const { updateSponsors } = sponsors.actions;
export default sponsors.reducer;
