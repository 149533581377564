import React from "react";
import { updateUiState } from "../../Store/actions/ui/ui";
import { useAppDispatch } from "../../Store";

import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { DepositButtonProps } from "./DepositButton.types";
import { EventStateCommand } from "../../Store/actions/event/event.types";
import { CompleteState } from "../../Store/CompleteReducer";
import { useSelector } from "react-redux";
import { convertToGMTPlus1 } from "../../Utils.logik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next"; // Importez useTranslation ici
import { CiCreditCard1 } from "react-icons/ci";

function DepositButton(props: DepositButtonProps) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const event: EventStateCommand = useSelector(
    (state: CompleteState) => state.event
  );
  const currentTime = new Date(Date.now());

  // Dates de l'événement
  const beginDepositDateTime = event.beginDepositDateTime
    ? convertToGMTPlus1(new Date(event.beginDepositDateTime))
    : null;
  const beginDateTime = event.beginDateTime
    ? convertToGMTPlus1(new Date(event.beginDateTime))
    : null;
  const endDateTime = event.endDateTime
    ? convertToGMTPlus1(new Date(event.endDateTime))
    : null;

  // Logique pour afficher le bouton
  const showDepositButton =
    ((beginDepositDateTime && currentTime > beginDepositDateTime) ||
      (beginDateTime && currentTime > beginDateTime)) &&
    endDateTime &&
    currentTime < endDateTime;


  const DepositButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "3px 12px",
    lineHeight: 1.5,
    backgroundColor: "#8958fe",
    color: "white",
    borderRadius: "50px",
    borderColor: "#8958fe",
    fontFamily: "Montserrat",
    animation: "pulse 3s infinite",
    margin: "10px",
    border: "1px solid white",
    "&:hover": {
      backgroundColor: "#6744bd",
      borderColor: "#6744bd",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#6744bd",
      borderColor: "#6744bd",
    },
    "&:disabled": {
      color: "white",
      backgroundColor: "grey",
      animation: "none",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  });
  const handleClickDeposit = () => {
    dispatch(updateUiState({ formDepositAmount: true }));
  };
  return showDepositButton ? (
    <DepositButton onClick={handleClickDeposit} className="blob">
      {props.text === undefined ? (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {/* <PiHandCoinsFill/> */}
          <CiCreditCard1 />
          {t("Balance.TopUp.Button")}{" "}
        </div>
      ) : (
        props.text
      )}
    </DepositButton>
  ) : (
    <div
      onClick={() =>
        toast.error(t("Balance.Event.IsFinish"), { position: "top-center" })
      }
      style={{ fontSize: "0.7rem" }}
    >
      <u>{t("Balance.Deposit.NotPossible")}</u>
    </div>
  );
}

export default DepositButton;
