import React from "react";
import {
  stylePaymentMethodContainer,
  stylePaymentMethodIcon,
} from "./PaymentMethodIcons.styles";
import { useTranslation } from "react-i18next";



interface PaymentMethod {
  titre: string;
  imageName: string;
  className: string;
  customStyle?: { [key: string]: string };
}

function PaymentMethodIcons() {
  const { t } = useTranslation();

  const methodPayment: PaymentMethod[] = [
    {
      titre: "twint",
      imageName: "twint.jpg",
      className: "payment-icons-twint",
    },
    {
      titre: "visa",
      imageName: "visa.jpg",
      className: "payment-icons-visa",
    },
    {
      titre: "mastercard",
      imageName: "mastercard.jpg",
      className: "payment-icons-mastercard",
    },
    {
      titre: "maestro",
      imageName: "maestro.jpg",
      className: "payment-icons-maestro",
    },
    {
      titre: "applepay",
      imageName: "applepay.jpg",
      className: "payment-icons-applepay",
    },
    {
      titre: "samsungpay",
      imageName: "samsungpay.jpg",
      className: "payment-icons-samsungpay",
      customStyle:{border: '1px solid black'}
    },
    {
      titre: "postfinance",
      imageName: "postfinance.jpg",
      className: "payment-icons-postfinance",
      customStyle:{border: '1px solid black'}
    },
  ];
  return (
    <div>
      <div>
        <div className="payment-icons-container">
          <div
            className="payment-icons-twint"
            style={stylePaymentMethodContainer}
          >
            {methodPayment.map((p) => (
              <img
              key={p.titre}
                alt={p.titre}
                className={`payment-method-icon ${p.titre}`}
                style={{ ...stylePaymentMethodIcon, ...p.customStyle }}

                src={process.env.PUBLIC_URL + `/payment-method/${p.imageName}`}
              ></img>
            ))}
          </div>
        </div>
        <span style={{ fontSize: "0.6rem" }}>
          {t('Transactions.SecurisedBy.Payrexx')}
        </span>
      </div>
    </div>
  );
}

export default PaymentMethodIcons;
