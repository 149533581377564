//export const URL_API = 'http://tantardini.internet-box.ch:8080'
// export const URL_API = 'http://192.168.1.24:5000'
export const URL_API =
  window.location.href.indexOf("test.") >
  -1 || window.location.href.indexOf("localhost") > -1
    ? "https://test.core.strapnpay.ch"
    : "https://core.strapnpay.ch";

const headers: {
  Accept: string;
  "X-Api-Key"?: string;
  "X-Strap-Encrypted-Identifier"?: string;
} = {
  Accept: "application/json",
};

export let config = {
  method: "get",
  url: "",
  headers: headers,
};
