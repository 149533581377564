import { useSelector } from "react-redux";
import { CompleteState } from "../../Store/CompleteReducer";

function Header() {
  //Retrieve data
  const event = useSelector((state: CompleteState) => state.event);
  return (
    <div>
      {" "}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          boxShadow:
            "rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px",
          padding: "5px",
          background: "white",
        }}
      >
        <div>
          <span
            style={{
              fontWeight: "700",
            }}
          >
            {event.title}
            <br />
          </span>
          <span
            style={{
              fontSize: "0.8rem",
            }}
          >
            {event.description}
          </span>
        </div>
        <img
          src={event.strapMockupUrl}
          alt={event.title}
          style={{
            width: "50px",
            height: "50px",
            objectFit: "scale-down",
          }}
        ></img>
      </div>
    </div>
  );
}

export default Header;
