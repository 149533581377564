import React from "react";
import {
  styleTransactionItemAmountNegative,
  styleTransactionItemAmountPositive,
  styleTransactionItemContainerMainContainer,
  styleTransactionItemContainerSecondaryContainer,
  styleTransactionItemCurrency,
  styleTransactionItemDate,
  styleTransactionItemIcon,
  styleTransactionItemLabel,
} from "./TransactionItem.styles";
import { TransactionItemProps, TransactionType } from "./TransactionItem.types";
import { Tooltip, styled } from "@mui/material";
import Box from "@mui/system/Box/Box";
import grey from "@mui/material/colors/grey";
import Drawer from "@mui/material/Drawer";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/fr";
import moment from "moment";
import {
  getPrintTextByIdExpenseType,
  getTitleByIdDepositType,
} from "./Transactions.logik";
import { t } from "i18next";
import { IoPricetagsOutline } from "react-icons/io5";
export const TRANSACTIONITEM_ICON_DIMENSIONS = 35;

function TransactionItem(props: TransactionItemProps) {
  const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)",
  }));


  const renderIcon = () => {
    switch (props.transactionType) {
      case TransactionType.Pending:
      case TransactionType.DepositVisa:
      case TransactionType.DepositMastercard:
      case TransactionType.DepositTwint:
        return (
          <img
            src={process.env.PUBLIC_URL + `/transactions/types/card.png`}
            alt="deposit_online"
            height={TRANSACTIONITEM_ICON_DIMENSIONS}
            width={TRANSACTIONITEM_ICON_DIMENSIONS}
          />
        );
      case TransactionType.DepositCash:
        return (
          <img
            src={process.env.PUBLIC_URL + `/transactions/types/cash.png`}
            alt="deposit_cash"
            height={TRANSACTIONITEM_ICON_DIMENSIONS}
            width={TRANSACTIONITEM_ICON_DIMENSIONS}
          />
        );
      case TransactionType.Purchase:
        return (
          <img
            src={process.env.PUBLIC_URL + `/transactions/types/purchase.png`}
            alt="purchase"
            height={TRANSACTIONITEM_ICON_DIMENSIONS}
            width={TRANSACTIONITEM_ICON_DIMENSIONS}
          />
        );

      default:
        return (
          <img
            src={process.env.PUBLIC_URL + `/transactions/types/card.png`}
            alt="deposit_cash"
            height={TRANSACTIONITEM_ICON_DIMENSIONS}
            width={TRANSACTIONITEM_ICON_DIMENSIONS}
          />
        );
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const defineRealAmount = () => {
    if((props.products && props.products?.length > 0)){
      return props.amount * -1
    }else if(props.refNo === "expense"){
      return props.amount * -1
    }else{
      return props.amount
    }
  }
  return (
    <>
      <div
        style={styleTransactionItemContainerMainContainer}
        onClick={handleClickOpen}
      >
        <div style={styleTransactionItemContainerSecondaryContainer}>
          <div style={styleTransactionItemIcon} className="transaction_type">
            {props.refNo === "expense" ? (
              <img
                src={
                  process.env.PUBLIC_URL +
                  `/transactions/types/${
                    props.transactionType && props.transactionType > 1
                      ? "expense"
                      : "refund"
                  }.png`
                }
                alt="purchase"
                height={TRANSACTIONITEM_ICON_DIMENSIONS}
                width={TRANSACTIONITEM_ICON_DIMENSIONS}
              />
            ) : (
              renderIcon()
            )}
          </div>
          <div>
            <div className="transaction_date" style={styleTransactionItemDate}>
              <Moment format="D MMMM YYYY HH:mm" tz="Europe/Paris">
                {moment.utc(props.createsTimestamp).local().format()}
              </Moment>
            </div>
            <div
              className="transaction_label"
              style={styleTransactionItemLabel}
            >
              <div style={{display:'flex',flexDirection:'row', alignItems:'center', gap:'5px'}}>
                {props.title}{(props.expenses && props.expenses?.length > 0) ? <IoPricetagsOutline /> : null}
                {props.idDepositState !== undefined &&
                props.idDepositState !== null &&
                props.idDepositState >= 6 &&
                props.idDepositState <= 9 ? (
                  <span
                    style={{
                      border: "1px solid rgb(8, 82, 251)",
                      borderRadius: "10px",
                      fontSize: "0.6rem",
                      padding: "3px 5px",
                      //position: "absolute",
                    }}
                  >
                    <div>{t("Transaction.Item.Refunded")}</div>
                  </span>
                ) : null}
                {props.refundable === false ? (
                  <span
                    style={{
                      border: "1px solid rgb(8, 82, 251)",
                      borderRadius: "10px",
                      fontSize: "0.6rem",
                      padding: "3px 5px",
                      //position: "absolute",
                    }}
                  >
                    Non-remboursable
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className="transaction_currency"
            style={styleTransactionItemCurrency}
          >
            CHF
          </div>
          <div
            style={defineRealAmount() > 0 ? styleTransactionItemAmountPositive : styleTransactionItemAmountNegative
              
              // props.refNo === "expense"
              //   ? props.transactionType === 4
              //     ? styleTransactionItemAmountPositive
              //     : props.amount <= 0
              //     ? styleTransactionItemAmountPositive
              //     : styleTransactionItemAmountNegative
              //   : props.amount <= 0
              //   ? styleTransactionItemAmountNegative
              //   : styleTransactionItemAmountPositive
            }
          >
            {/* {props.refNo === "expense" &&
            props.transactionType &&
            props.transactionType < 4
              ? "-"
              : ""}
            {props.refNo === "expense" &&
            props.transactionType &&
            props.transactionType === 4
              ? ((props.amount * -1) / 100).toFixed(2)
              : (props.amount / 100).toFixed(2)} */}
              {(defineRealAmount()/100).toFixed(2)}
          </div>
        </div>
      </div>

      <div>
        <Drawer
          PaperProps={{
            sx: { maxHeight: "70%", borderRadius: "10px 10px 0 0" },
          }}
          anchor="bottom"
          open={open}
          onClose={handleClose}
        >
          <div onClick={handleClose}>
            <Puller></Puller>
          </div>
          <div
            className="content"
            style={{
              padding: "20px",
            }}
          >
            {props.idTransaction !== undefined || props.refNo === "expense" ? (
              props.refNo === "expense" ? (
                <>
                  <div
                    className="transactionDetails"
                    style={{ margin: "10px", lineHeight: 1.2 }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <b>{t("Transaction.Item.Detail")}</b>
                    </div>
                    <div>
                      {props.transactionType
                        ? getPrintTextByIdExpenseType(props.transactionType)
                            .detail
                        : null}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="transactionDetails"
                    style={{ margin: "10px", lineHeight: 1.2 }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <b>{t("Transaction.Item.Detail")}</b>
                    </div>
                    <div>
                      {t("Transaction.Item.PaymentMethod")}:{" "}
                      {props.transactionType
                        ? getTitleByIdDepositType(props.transactionType)
                        : null}
                    </div>
                    <div>RefNo: {props.refNo}</div>
                    <div>idTransaction: {props.idTransaction}</div>
                  </div>
                </>
              )
            ) : (
              <table
                style={{
                  lineHeight: "1.2rem",
                  marginBottom: "10px",
                  borderSpacing: "10px 4px",
                  width: "100%",
                }}
              >
                <thead>
                  <tr>
                    <th align="left">{t("Common.Description")}</th>
                    <th align="center">{t("Common.Qty")}</th>
                    <th align="right">{t("Common.Total")}</th>
                  </tr>
                </thead>
                <tbody>
                  {props.products !== undefined &&
                    props.products.map((product, index) => (
                      <tr key={index}>
                        <td align="left">{product.product?.title}</td>
                        <td align="center">{product.quantity}</td>
                        <td align="right" style={{ whiteSpace: "nowrap" }}>
                          <Tooltip
                            title={`${product.quantity} x ${(
                              product.salePointProduct.price / 100
                            ).toFixed(2)} CHF`}
                            placement="left"
                          >
                            <span>
                              {(
                                ((product.salePointProduct.price *
                                  product.quantity) /
                                  100) *
                                -1
                              ).toFixed(2)}
                            </span>
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  {props.expenses !== undefined &&
                    props.expenses.map((expense, index) => (
                      <tr key={index}>
                        <td align="left" style={{display:'flex',flexDirection:'row', alignItems:'center',gap:'5px'}}>
                          <IoPricetagsOutline />
                          {expense.strapVoucher.voucher.title}
                        </td>
                        {/* make dynamic quantity */}
                        <td align="center">{1}</td>
                        <td align="right" style={{ whiteSpace: "nowrap" }}>
                          <Tooltip title={`1 CHF`} placement="left">
                            <span>
                              {((expense.amount / 100) * -1).toFixed(2)}
                            </span>
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
            <div
              className="footer"
              style={{
                textAlign: "right",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: "10px",
                borderTop: "2px dashed #00000033",
              }}
            >
              <div className="total_label" style={{ fontWeight: "bold" }}>
                {t("Common.Total")}
                {" (CHF)"}{" "}
              </div>
              <div
                className="totalCurrencyAmount"
                style={{
                  fontSize: "1.5rem",
                  background:
                    "linear-gradient(135deg, rgb(8, 82, 251) 10%, rgb(9, 167, 250))",
                  borderRadius: "4px",
                  color: "white",
                  width: "fit-content",
                  display: "inline",
                  padding: "2px 5px",
                  fontWeight: "bold",
                }}
              >
                {(props.amount / 100).toFixed(2)}
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
}

export default TransactionItem;
